import { ReactNode, useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AuthContext } from 'contexts/AuthContext';
import {
  BASE_CUSTOMER_ROUTE,
  BASE_PROVIDER_ROUTE
} from 'app/Router/Router.constants';

const AccountTypeBlocker: React.FC<AccountTypeBlockerProps> = ({
  children
}) => {
  const { userType } = useContext(AuthContext);
  const { pathname } = useLocation();

  const isCustomer = userType === 'CUSTOMER';
  const isProvider = userType === 'PROVIDER';
  const isCustomerRoute = pathname.includes('/customers');
  const isProviderRoute = pathname.includes('/providers');

  if (isProvider && isCustomerRoute) {
    return <Navigate to={BASE_PROVIDER_ROUTE} replace />;
  }

  if (isCustomer && isProviderRoute) {
    return <Navigate to={BASE_CUSTOMER_ROUTE} replace />;
  }

  return <>{children}</>;
};

interface AccountTypeBlockerProps {
  children: ReactNode;
}

export default AccountTypeBlocker;
