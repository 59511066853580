import api from 'api';
import { motion } from 'framer-motion';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Form, Select, Textarea } from 'components/ui';
import { FieldValues, useForm } from 'react-hook-form';
import { feedbackSchema } from 'utils/yup/schemas/common';
import { yupResolver } from 'utils/yup';
import { FeedbackSubject } from 'types';
import { isSmallerThan } from 'theme/foundations/breakpoints';
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  FlexProps,
  Heading,
  SelectProps,
  Spacer,
  Text,
  TextareaProps,
  useMediaQuery,
  useToast
} from '@chakra-ui/react';

const MotionFlex = motion(Flex);

const subjects: { label: string; value: FeedbackSubject }[] = [
  { label: 'Sugerir uma nova funcionalidade', value: 'SUGGESTION' },
  { label: 'Sugerir uma melhoria', value: 'IMPROVEMENT' },
  { label: 'Solicitar a adição de um novo serviço', value: 'NEW_SERVICE' },
  { label: 'Tenho um problema', value: 'ISSUE' },
  { label: 'Outro', value: 'OTHER' }
];

const Feedback: React.FC<FeedbackProps> = ({
  containerProps,
  textareaProps,
  selectProps,
  cancelText,
  subject,
  subtitle,
  title,
  onCancel
}) => {
  const [isMobile] = useMediaQuery(isSmallerThan('md'));
  const navigate = useNavigate();
  const toast = useToast();

  const { isLoading, mutateAsync } = useMutation(api.feedbacks.create);

  const methods = useForm({
    resolver: yupResolver(feedbackSchema),
    defaultValues: {
      subject,
      description: ''
    }
  });
  const { watch } = methods;

  const descriptionWatch = watch('description');
  const handleGoBack = () => navigate(-1);

  const handleSubmitForm = async (fieldValues: FieldValues) => {
    try {
      await mutateAsync(fieldValues);
      toast({
        status: 'success',
        description:
          'Feedback enviado com sucesso. Vamos analisar seu pedido e se necessário entraremos em contato com você.',
        position: 'top-right',
        duration: 4000
      });
      handleGoBack();
    } catch (err) {
      toast({
        status: 'error',
        description: (err as Error).message,
        position: 'top-right',
        duration: 1000
      });
    }
  };

  return (
    <MotionFlex
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      flex={1}
      direction="column"
      rowGap={6}
      pt={8}
      pb={4}
      px={6}
      {...containerProps}>
      <Box mb={3}>
        <Heading fontSize="xl" mb={1}>
          {title ?? 'Deixe seu feeback e contribua para evolução da plataforma'}
        </Heading>
        <Text fontSize="md">
          {subtitle ??
            'Utilize o campo abaixo para relatar falhas ou sugerir novas funcionalidades'}
        </Text>
      </Box>
      <Form
        flex={1}
        methods={methods}
        onSubmit={methods.handleSubmit(handleSubmitForm)}>
        {!subject && (
          <Select
            name="subject"
            label="Assunto"
            options={subjects}
            unselectedText="Selecione um assunto"
            {...selectProps}
          />
        )}
        <Textarea
          name="description"
          label={`Seu feedback (${descriptionWatch.length}/1000)`}
          rows={15}
          maxLength={1000}
          {...textareaProps}
        />
        <Spacer />
        <ButtonGroup
          as={Flex}
          gap={2}
          spacing={0}
          direction={isMobile ? 'column' : 'row'}
          alignSelf={isMobile ? 'strech' : 'flex-end'}>
          <Button onClick={onCancel} disabled={isLoading}>
            {cancelText ?? 'Cancelar'}
          </Button>
          <Button
            type="submit"
            colorScheme="blue"
            isDisabled={!descriptionWatch?.length}
            isLoading={isLoading}>
            Enviar
          </Button>
        </ButtonGroup>
      </Form>
    </MotionFlex>
  );
};

interface FeedbackProps {
  containerProps?: FlexProps;
  selectProps?: SelectProps;
  textareaProps?: TextareaProps;
  subject?: FeedbackSubject;
  subtitle?: string;
  title?: string;
  cancelText?: string;
  onCancel: () => void;
}

export default Feedback;
