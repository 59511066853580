import { StyleConfig } from '@chakra-ui/theme-tools';

const Text: StyleConfig = {
  baseStyle: {
    fontSize: 'sm',
    fontWeight: 'regular',
    color: 'gray.700'
  }
};

export default Text;
