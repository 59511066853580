import { StyleConfig, SystemStyleFunction } from '@chakra-ui/theme-tools';

const varSolid: SystemStyleFunction = ({ colorScheme }) => {
  if (colorScheme === 'black') {
    return {
      bg: 'black',
      fontSize: 'sm',
      fontWeight: 'semibold',
      color: 'white',
      rounded: 'md',
      _hover: {
        bg: 'blackAlpha.800'
      },
      _active: {
        bg: 'blackAlpha.800'
      }
    };
  }

  if (colorScheme === 'gray') {
    return {
      bg: 'gray.100',
      fontSize: 'sm',
      fontWeight: 'semibold',
      color: 'gray.900',
      rounded: 'md'
    };
  }

  return {
    fontSize: 'sm',
    fontWeight: 'semibold',
    rounded: 'md'
  };
};

const varLink: SystemStyleFunction = () => {
  return {
    fontSize: 'sm',
    fontWeight: 'medium',
    color: 'gray.900',
    rounded: 'md'
  };
};

const varOutline: SystemStyleFunction = ({ colorScheme }) => {
  return {
    borderWidth: 1,
    borderColor: 'gray.400',
    _hover: {
      bg: 'blackAlpha.100'
    }
  };
};

const varNavbarButton: SystemStyleFunction = () => {
  return {
    h: '100%',
    rounded: 'none',
    transition: '0.3s',
    fontSize: '15px',
    fontWeight: 'medium',
    color: 'whiteAlpha.600',
    colorScheme: 'transparent',
    borderBottomWidth: 3,
    borderColor: 'transparent',
    _hover: {
      borderColor: 'white'
    },
    _active: {
      color: 'white',
      borderColor: 'white',
      fontWeight: 'semibold'
    }
  };
};

const varNavbarTab: SystemStyleFunction = () => {
  return {
    w: '180px',
    h: '40px',
    fontSize: 'sm',
    fontWeight: 'medium',
    color: 'whiteAlpha.600',
    display: 'flex',
    rounded: 'none',
    variant: 'unstyled',
    alignItems: 'center',
    roundedTop: 'md',
    borderBottomWidth: 5,
    borderColor: 'transparent',
    bg: 'transparent',
    _active: {
      borderColor: 'white',
      bg: 'whiteAlpha.50',
      fontWeight: 'semibold',
      color: 'white'
    }
  };
};

const Button: StyleConfig = {
  baseStyle: {
    boxShadow: 'none',
    lineHeight: 0,
    _focus: {
      boxShadow: 'none'
    }
  },
  variants: {
    solid: varSolid,
    link: varLink,
    outline: varOutline,
    navbarButton: varNavbarButton,
    navbarTab: varNavbarTab
  }
};

export default Button;
