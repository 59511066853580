import Button from './Button.theme';
import Input from './Input.theme';
import Select from './Select.theme';
import Textarea from './Textarea.theme';
import FormLabel from './FormLabel.theme';
import Text from './Text.theme';
import Heading from './Heading.theme';
import FormError from './FormError.theme';
import Tabs from './Tabs.theme';

const components = {
  Button,
  Input,
  Textarea,
  Select,
  FormLabel,
  Text,
  Heading,
  FormError,
  Tabs
};

export default components;
