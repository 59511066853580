import { KeyboardEvent } from 'react';
import TaggedAutosuggestMenuItem from './TaggedAutosuggestMenuItem';
import { VStack, StackDivider, StackProps } from '@chakra-ui/react';
import { Option } from 'types';

const TaggedAutosuggestMenu: React.FC<TaggedAutosuggestMenuProps> = ({
  _menu,
  focusedItem,
  searchResult,
  isNotCheckedOption,
  isShowingSearchResults,
  onListKeyDown,
  onSelectOption
}) => {
  if (isShowingSearchResults) {
    return (
      <VStack
        as="ul"
        w="100%"
        top="80px"
        bg="white"
        spacing={0}
        shadow="xl"
        rounded="md"
        zIndex="modal"
        overflow="hidden"
        position="absolute"
        direction="column"
        listStyleType="none"
        borderWidth={1}
        borderColor="gray.400"
        divider={<StackDivider borderColor="gray.100" />}
        onKeyDown={onListKeyDown}
        {..._menu}>
        {searchResult.map((option, index) =>
          isNotCheckedOption(option) ? (
            <TaggedAutosuggestMenuItem
              index={index}
              option={option}
              key={option.value}
              focusedItem={focusedItem}
              onSelectOption={onSelectOption}
            />
          ) : null
        )}
      </VStack>
    );
  }

  return null;
};

interface TaggedAutosuggestMenuProps {
  _menu?: StackProps;
  searchResult: Option[];
  onListKeyDown: HandleListKeyDown;
  isNotCheckedOption: (option: Option) => boolean;
  isShowingSearchResults: boolean;
  focusedItem: number | null;
  onSelectOption: (option: Option) => void;
}

type HandleListKeyDown = (e: KeyboardEvent<HTMLDivElement>) => void;

export default TaggedAutosuggestMenu;
