import { Button, Center } from '@chakra-ui/react';

const ListLoadMoreButton: React.FC<ListLoadMoreButtonProps> = ({
  isLoading,
  onClick
}) => {
  return (
    <Center p={4}>
      <Button size="sm" isLoading={isLoading} onClick={onClick} px={6}>
        Ver mais
      </Button>
    </Center>
  );
};

interface ListLoadMoreButtonProps {
  isLoading: boolean;
  onClick: () => void;
}

export default ListLoadMoreButton;
