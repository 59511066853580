import { StyleConfig, SystemStyleFunction } from '@chakra-ui/theme-tools';

export const varOutline: SystemStyleFunction = props => {
  return {
    rounded: 'lg',
    fontSize: 'md',
    fontWeight: 'medium',
    borderColor: 'gray.400',
    color: 'gray.900',
    _placeholder: {
      color: 'gray.600',
      fontWeight: 'regular'
    }
  };
};

const Textarea: StyleConfig = {
  variants: {
    outline: varOutline
  }
};

export default Textarea;
