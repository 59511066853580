import { useEffect, useState } from 'react';
import { FiWifiOff } from 'react-icons/fi';
import {
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Heading,
  Text,
  Box,
  Circle,
  useToast
} from '@chakra-ui/react';

const NetworkStatusFeedback: React.FC = () => {
  const [isConnected, setIsConnected] = useState(true);
  const toast = useToast();

  useEffect(() => {
    const handleOnline = () => {
      setIsConnected(true);
      toast({
        status: 'success',
        description: 'Conexão com a internet recuperada',
        position: 'top-right',
        duration: 1000
      });
    };

    const handleOffline = () => {
      setIsConnected(false);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, [toast]);

  if (!isConnected) {
    return (
      <Modal size="full" isOpen={true} onClose={() => {}}>
        <ModalOverlay />
        <ModalContent rounded="none">
          <ModalBody
            display="flex"
            alignItems="center"
            flexDirection="column"
            justifyContent="center"
            rowGap={8}
          >
            <Circle bg="red.100" p={5}>
              <Icon as={FiWifiOff} w="200px" h="200px" color="red.500" />
            </Circle>
            <Box>
              <Heading fontWeight="bold" textAlign="center" mb={1}>
                Sem conexão com a internet
              </Heading>
              <Text textAlign="center">
                Verifique sua conexão com a internet e tente novamente
              </Text>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }

  return null;
};

export default NetworkStatusFeedback;
