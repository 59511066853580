import http from 'api/http';
import { FieldValues } from 'react-hook-form';
import { Favorite } from 'types';
import { createFormData } from 'utils/functions';
import { omitBy, isNull } from 'lodash';

interface CustomerCreateData {
  firstName?: string;
  lastName?: string;
  avatar?: string | null;
}

const customers = {
  create: async (fieldValues: CustomerCreateData) => {
    const { data, status } = await http.post('/customers/create', fieldValues);
    if (status !== 201)
      throw new Error('Ocorreu um erro, tente novamente mais tarde.');
    return data;
  },
  update: async ({ customerId, fieldValues }: UpdateParams) => {
    const formatted = fieldValues;
    if (typeof formatted.avatar !== 'object') {
      delete formatted.avatar;
    }

    const { data, status } = await http.patch(
      `/customers/${customerId}`,
      createFormData(omitBy(formatted, isNull))
    );

    if (status !== 200)
      throw new Error('Ocorreu um erro, tente novamente mais tarde.');
    return data;
  },
  check: async () => {
    const { data, status } = await http.get<{ hasAccount: boolean }>(
      '/customers/check'
    );
    if (status !== 200)
      throw new Error('Ocorreu um erro, tente novamente mais tarde.');
    return data;
  },
  get: async () => {
    const { status, data } = await http.get('/customers');
    if (status !== 200)
      throw new Error('Ocorreu um erro, tente novamente mais tarde.');
    return { data, status };
  },
  getFavorites: async () => {
    const { status, data } = await http.get<{
      favorites: Favorite[];
    }>('/customers/favorites');
    if (status !== 200)
      throw new Error('Ocorreu um erro, tente novamente mais tarde.');
    return { ...data, status };
  },
  addNotificationToken: async (token: string) => {
    const { data, status } = await http.patch(`/customers/notification/token`, {
      token
    });

    if (status !== 200)
      throw new Error('Ocorreu um erro, tente novamente mais tarde.');

    return data;
  },
  updateLocation: async ({
    customerId,
    locationId
  }: {
    customerId: string;
    locationId: string;
  }) => {
    const { data, status } = await http.patch(
      `/customers/${customerId}/update/location`,
      {
        location: locationId
      }
    );

    if (status !== 200)
      throw new Error('Ocorreu um erro, tente novamente mais tarde.');

    return data;
  }
};

interface UpdateParams {
  customerId: string;
  fieldValues: FieldValues;
}

export type { CustomerCreateData };
export default customers;
