import { Avatar, AvatarBadge, Flex, Heading, Text } from '@chakra-ui/react';

const ListItem: React.FC<ListItemProps> = ({
  avatar,
  avatarBadge,
  topText,
  mainText,
  bottomText,
  payload,
  isSelected,
  onClick
}) => {
  return (
    <Flex
      as="button"
      role="button"
      display="flex"
      align="center"
      textAlign="start"
      transition="0.3s"
      bg={isSelected ? 'gray.50' : 'white'}
      onClick={() => onClick(payload)}
      _hover={{
        bg: 'gray.50'
      }}
      gap={5}
      px={6}
      py={3}>
      {avatar && (
        <Avatar w="60px" h="60px" borderRadius="xl" rounded="xl" src={avatar}>
          {!!avatarBadge && (
            <AvatarBadge boxSize="1.25em" bg="blue.500">
              <Text as="small" fontSize="10px" fontWeight="bold" color="white">
                {avatarBadge}
              </Text>
            </AvatarBadge>
          )}
        </Avatar>
      )}
      <Flex flex={1} align="center" justify="space-between">
        <Flex direction="column">
          {topText && (
            <Text color="gray.800" fontSize="12px" fontWeight="medium">
              {topText}
            </Text>
          )}
          {mainText && (
            <Heading fontSize="md" fontWeight="medium" noOfLines={2}>
              {mainText}
            </Heading>
          )}
          {bottomText && (
            <Text fontSize="xs" fontWeight="regular" noOfLines={1}>
              {bottomText}
            </Text>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

interface ListItemProps {
  isSelected?: boolean;
  avatar?: string;
  avatarBadge?: number;
  topText?: string;
  mainText?: string;
  bottomText?: string;
  payload?: any;
  onClick: (payload: any) => void;
}

export default ListItem;
