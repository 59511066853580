import { StyleConfig } from '@chakra-ui/theme-tools';
import { varOutline } from 'theme/components/Input.theme';

const Select: StyleConfig = {
  variants: {
    outline: varOutline
  }
};

export default Select;
