import http from '../http';

const chats = {
  login: async (userId: string) => {
    const { status, data } = await http.post<{ token: string }>(
      '/admin/login',
      {
        userUid: userId
      }
    );
    if (status !== 200) {
      throw new Error('Ocorreu um erro, tente novamente mais tarde.');
    }

    return data.token;
  }
};

export default chats;
