import { ReactElement } from 'react';
import { motion } from 'framer-motion';
import { BoxProps, Flex, Heading, Text, TextProps } from '@chakra-ui/react';

const MotionFlex = motion(Flex);

const NoData: React.FC<NoDataProps> = ({
  link,
  icon,
  title,
  description,
  _title,
  _container,
  _description
}) => {
  return (
    <MotionFlex
      flex={1}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      as="section"
      direction="column"
      align="center"
      rowGap={8}
      py="64px"
      {..._container}
    >
      <Flex direction="column" align="center" rowGap={2} mb={5}>
        {icon}
        <Heading textAlign="center" fontSize={['lg', 'xl']} {..._title}>
          {title}
        </Heading>
        {description && (
          <Text
            mb={2}
            maxW="300px"
            fontSize="sm"
            textAlign="center"
            fontWeight="medium"
            {..._description}
          >
            {description}
          </Text>
        )}
        {link && link}
      </Flex>
    </MotionFlex>
  );
};

interface NoDataProps {
  title: string;
  description?: string;
  icon?: ReactElement;
  link?: ReactElement;
  _title?: TextProps;
  _description?: TextProps;
  _container?: BoxProps;
}

export default NoData;
