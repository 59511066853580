import { StyleConfig } from '@chakra-ui/theme-tools';

const Heading: StyleConfig = {
  baseStyle: {
    fontWeight: 'semibold',
    color: 'gray.900'
  }
};

export default Heading;
