import ReactGA from 'react-ga4';

const selectedProviderContext = () => {
  ReactGA.event({
    category: 'landing',
    action: 'selected-provider-context'
  });
};

const selectedCustomerContext = () => {
  ReactGA.event({
    category: 'landing',
    action: 'selected-customer-context'
  });
};

const downloadPWA = () => {
  ReactGA.event({
    category: 'landing',
    action: 'download-PWA'
  });
};

export { selectedProviderContext, selectedCustomerContext, downloadPWA };
