import { useEffect, useRef } from 'react';
import { FiChevronRight } from 'react-icons/fi';
import {
  Text,
  Icon,
  Button,
  ButtonProps,
  TextProps,
  IconProps
} from '@chakra-ui/react';
import { Option } from 'types';

const TaggedAutosuggestMenuItem: React.FC<TaggedAutosuggestMenuItemProps> = ({
  index,
  option,
  _button,
  _text,
  _icon,
  onSelectOption,
  focusedItem
}) => {
  const menuItemRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (focusedItem === index) {
      menuItemRef.current?.focus();
    }
  }, [focusedItem, index]);

  return (
    <Button
      ref={menuItemRef}
      size="lg"
      w="100%"
      fontSize="md"
      fontWeight="medium"
      rounded="none"
      variant="ghost"
      alignItems="center"
      justifyContent="space-between"
      _focus={{ bg: 'blue.50' }}
      onClick={() => onSelectOption(option)}
      {..._button}>
      <Text fontSize="md" color="gray.900" {..._text}>
        {option.label}
      </Text>
      <Icon as={FiChevronRight} w="20px" h="20px" {..._icon} />
    </Button>
  );
};

interface TaggedAutosuggestMenuItemProps {
  index: number;
  option: Option;
  _button?: ButtonProps;
  _text?: TextProps;
  _icon?: IconProps;
  focusedItem: number | null;
  onSelectOption: (option: Option) => void;
}

export default TaggedAutosuggestMenuItem;
