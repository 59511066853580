import { useContext } from 'react';
import { Customer } from '../types/entities';
import { AuthContext } from 'contexts/AuthContext';

const useAuthCustomer = () => {
  const data = useContext(AuthContext);
  const userData = data.userData as Customer;
  return { ...data, userData };
};

export default useAuthCustomer;
