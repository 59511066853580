import { useState } from 'react';
import { useAuthCustomer } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { useHotkeys } from 'react-hotkeys-hook';
import {
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  Button,
  ButtonGroup,
  Input,
  Flex
} from '@chakra-ui/react';

const AdminPanel: React.FC = () => {
  const [value, setValue] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const { loginAsAnotherUser } = useAuthCustomer();

  const { isOpen, onOpen, onClose } = useDisclosure();
  useHotkeys('ctrl+a', () => onOpen());

  const handleLoginWithUser = async () => {
    if (value) {
      setIsLoading(true);
      await loginAsAnotherUser(value);
      navigate('/');
      setIsLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody p={5}>
          <Flex align="center" gap={2}>
            <Input
              placeholder="UID"
              value={value}
              onChange={e => setValue(e.target.value)}
            />
            <ButtonGroup>
              <Button
                colorScheme="blue"
                disabled={!value.length}
                isLoading={isLoading}
                onClick={handleLoginWithUser}>
                Enter
              </Button>
            </ButtonGroup>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AdminPanel;
