import * as React from 'react';
import { useFormContext } from 'react-hook-form';

import {
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  InputGroup,
  Input as ChakraInput,
  InputProps as ChakraInputProps
} from '@chakra-ui/react';

const Input: React.FC<InputProps> = ({
  name,
  label,
  helperText,
  isRequired = false,
  showErrorMessage = true,
  ...props
}) => {
  const {
    register,
    formState: { errors }
  } = useFormContext();

  const errorMessage = errors[name]?.message;
  const dataTestIdErrorMessage = `error-message-${name}`;

  return (
    <FormControl isRequired={isRequired} isInvalid={!!errorMessage}>
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <InputGroup>
        <ChakraInput id={name} {...props} {...register(name)} />
      </InputGroup>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      {errorMessage && (
        <FormErrorMessage data-testid={dataTestIdErrorMessage}>
          {errorMessage}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};

interface InputProps extends ChakraInputProps {
  name: string;
  label?: string;
  helperText?: string;
  showErrorMessage?: boolean;
}

export default Input;
