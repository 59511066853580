import * as React from 'react';
import { useFormContext } from 'react-hook-form';

import {
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  Select as ChakraSelect,
  SelectProps as ChakraSelectProps
} from '@chakra-ui/react';

const Select: React.FC<SelectProps> = ({
  name,
  label,
  helperText,
  options,
  firstOption,
  isRequired = false,
  showErrorMessage = true,
  unselectedText,
  ...props
}) => {
  const {
    register,
    formState: { errors }
  } = useFormContext();

  const errorMessage = errors[name]?.message;

  return (
    <FormControl isRequired={isRequired} isInvalid={!!errorMessage}>
      {label && <FormLabel>{label}</FormLabel>}
      <ChakraSelect {...props} {...register(name)}>
        <option disabled value="">
          {unselectedText ?? 'Selecione'}
        </option>
        {options.length > 0 &&
          options.map(({ label, value }, i) => (
            <option key={i} value={value}>
              {label}
            </option>
          ))}
        {options.length === 0 && <option>Nenhuma opção disponível!</option>}
      </ChakraSelect>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  );
};

interface OptionProps {
  label: string;
  value: string;
}

interface SelectProps extends ChakraSelectProps {
  name: string;
  label?: string;
  helperText?: string;
  firstOption?: string;
  showErrorMessage?: boolean;
  unselectedText?: string;
  options: OptionProps[];
}

export default Select;
