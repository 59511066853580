import { useContext } from 'react';
import { Provider } from '../types/entities';
import { AuthContext } from 'contexts/AuthContext';

const useAuthProvider = () => {
  const data = useContext(AuthContext);
  const userData = data.userData as Provider;
  return { ...data, userData };
};

export default useAuthProvider;
