import { ReactComponent as IconGoogle } from 'assets/svg/google.svg';
import { Flex, Heading, Icon, Text } from '@chakra-ui/react';

const ButtonGoogle: React.FC<ButtonGoogleProps> = ({ title, onClick }) => {
  return (
    <Flex
      p={5}
      gap={4}
      h="85px"
      as="button"
      rounded="xl"
      fontSize="md"
      display="flex"
      align="center"
      borderWidth={1}
      borderColor="gray.400"
      justifyContent="flex-start"
      onClick={onClick}>
      <Icon as={IconGoogle} w="32px" h="32px" />
      <Flex direction="column" align="flex-start">
        <Heading fontSize={['md', 'lg', 'xl']}>{title}</Heading>
        <Text fontSize={['sm', 'md', 'md']} fontWeight="normal">
          Entrar com Google
        </Text>
      </Flex>
    </Flex>
  );
};

interface ButtonGoogleProps {
  title: string;
  onClick: () => void;
}

export default ButtonGoogle;
