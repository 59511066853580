import ReactGA from 'react-ga4';

const login = () => {
  ReactGA.event({
    category: 'providers',
    action: 'login'
  });
};

const updatePersonalData = () => {
  ReactGA.event({
    category: 'providers',
    action: 'update-personal'
  });
};

const updateDisctTest = () => {
  ReactGA.event({
    category: 'providers',
    action: 'update-disct-test'
  });
};

const updateService = () => {
  ReactGA.event({
    category: 'providers',
    action: 'update-service'
  });
};

const updateProviderBanner = () => {
  ReactGA.event({
    category: 'providers',
    action: 'update-banner'
  });
};

const questionAdd = () => {
  ReactGA.event({
    category: 'providers',
    action: 'question-add'
  });
};

const questionUpdate = () => {
  ReactGA.event({
    category: 'providers',
    action: 'question-update'
  });
};

const questionDelete = () => {
  ReactGA.event({
    category: 'providers',
    action: 'question-delete'
  });
};

const questionToggle = () => {
  ReactGA.event({
    category: 'providers',
    action: 'question-toggle'
  });
};

export {
  login,
  updateService,
  updateDisctTest,
  updatePersonalData,
  updateProviderBanner,
  questionAdd,
  questionUpdate,
  questionDelete,
  questionToggle
};
