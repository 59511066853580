import api from 'api';
import { useState } from 'react';
import { useAuthProvider } from 'hooks';
import { useMutation } from 'react-query';
import { parseFirebaseError } from 'utils/parseFirebaseError';
import { parseGoogleUser } from 'utils/parseGoogleUser';
import { useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const useProviderLogin = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();

  const { signInWithGoogle, setTempUser, setUserType } = useAuthProvider();
  const { mutateAsync: checkProvider } = useMutation(api.providers.check);

  const handleProviderLogin = async () => {
    try {
      setIsLoading(true);
      const { result } = await signInWithGoogle();
      const { hasAccount } = await checkProvider();
      if (hasAccount) {
        setUserType('PROVIDER');
      } else {
        toast({
          status: 'info',
          title: 'Seja bem-vindo(a)!',
          description: 'Crie sua conta para continuar',
          position: 'top-right',
          duration: 4000
        });
        setTempUser(parseGoogleUser(result.user));
        navigate('/providers/register');
      }
    } catch (err) {
      toast({
        status: 'error',
        description: parseFirebaseError((err as Error).message),
        position: 'top-right',
        duration: 1000
      });
      setIsLoading(false);
    }
  };

  return {
    providerLoginLoading: isLoading,
    providerLogin: handleProviderLogin
  };
};

export default useProviderLogin;
