import ReactGA from 'react-ga4';

export const login = () => {
  ReactGA.event({
    category: 'customers',
    action: 'login'
  });
};

export const selectCategory = () => {
  ReactGA.event({
    category: 'customers',
    action: 'select-category'
  });
};

export const selectService = () => {
  ReactGA.event({
    category: 'customers',
    action: 'select-service'
  });
};

export const selectLocation = () => {
  ReactGA.event({
    category: 'customers',
    action: 'select-location'
  });
};

export const refetchSearch = () => {
  ReactGA.event({
    category: 'customers',
    action: 'refetch-search'
  });
};

export const selectProviderSearch = () => {
  ReactGA.event({
    category: 'customers',
    action: 'select-provider-search'
  });
};

export const selectProviderFavorites = () => {
  ReactGA.event({
    category: 'customers',
    action: 'select-provider-favorites'
  });
};

export const updateDisctTest = () => {
  ReactGA.event({
    category: 'customers',
    action: 'update-disct-test'
  });
};

export const chatStart = () => {
  ReactGA.event({
    category: 'customers',
    action: 'chat-start'
  });
};
