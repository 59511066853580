import { Chat } from 'types';
import http from '../http';

const chats = {
  create: async (providerId: string) => {
    const { status, data } = await http.post<{ chat: Chat }>('/chats', {
      providerId
    });
    if (status !== 201) {
      throw new Error('Ocorreu um erro, tente novamente mais tarde.');
    }

    return data.chat;
  }
};

export default chats;
