import { StyleConfig } from '@chakra-ui/theme-tools';

const FormLabel: StyleConfig = {
  baseStyle: {
    fontSize: 'sm',
    fontWeight: 'medium',
    color: 'gray.900'
  }
};

export default FormLabel;
