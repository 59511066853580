import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FiEye } from 'react-icons/fi';

import {
  Icon,
  IconButton,
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  InputRightElement,
  InputGroup,
  Input as ChakraInput,
  InputProps as ChakraInputProps
} from '@chakra-ui/react';

const InputPassword: React.FC<InputProps> = ({
  name,
  label,
  helperText,
  isRequired = false,
  showErrorMessage = true,
  type,
  ...props
}) => {
  const {
    register,
    formState: { errors }
  } = useFormContext();

  const [show, setShow] = useState(false);
  const errorMessage = errors[name]?.message;

  return (
    <FormControl isRequired={isRequired} isInvalid={!!errorMessage}>
      {label && <FormLabel>{label}</FormLabel>}
      <InputGroup>
        <ChakraInput
          {...props}
          type={show ? 'text' : 'password'}
          {...register(name)}
        />
        <InputRightElement>
          <IconButton
            size="sm"
            variant="ghost"
            aria-label="toggle"
            onClick={() => setShow(prev => !prev)}
            icon={<Icon as={FiEye} w="14px" h="14px" color="gray.900" />}
          />
        </InputRightElement>
      </InputGroup>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  );
};

interface InputProps extends ChakraInputProps {
  name: string;
  label?: string;
  helperText?: string;
  showErrorMessage?: boolean;
}

export default InputPassword;
