import TaggedAutosuggestInput from './TaggedAutosuggestInput';
import TaggedAutosuggestMenu from './TaggedAutosuggestMenu';
import { TaggedAutosuggestProps } from './TaggedAutosuggest.types';
import { Flex, Heading, Icon, Text } from '@chakra-ui/react';
import useTaggedAutosuggest from './useTaggedAutosuggest';
import TaggedAutosuggestTags from './TaggedAutosuggestTags';
import { GiWorld } from 'react-icons/gi';

const TaggedAutosuggest: React.FC<TaggedAutosuggestProps> = ({
  options,
  defaultTags,
  isDisabled = false,
  minTags,
  maxTags,
  onChangeTags,
  onSelectOption,
  checkboxToggle,
  _container,
  _inputWrapper,
  _inputGroup,
  _inputLeftElement,
  _input,
  _inputIcon,
  _inputSpinner,
  _menu,
  _tag,
  _tagLabel,
  _tagCloseButton
}) => {
  const {
    tags,
    value,
    searchTerm,
    searchResult,
    inputRef,
    containerRef,
    focusedItem,
    isLoading,
    isNotCheckedOption,
    isShowingSearchResults,
    handleRemoveTag,
    handleToggleMenu,
    handleInputChange,
    handleInputKeyDown,
    handleSelectOption,
    handleListKeyDown
  } = useTaggedAutosuggest({
    options,
    defaultTags,
    isDisabled,
    minTags,
    maxTags,
    onChangeTags,
    onSelectOption
  });

  return (
    <Flex
      ref={containerRef}
      direction="column"
      position="relative"
      {..._container}>
      <TaggedAutosuggestInput
        _input={_input}
        _inputIcon={_inputIcon}
        _inputGroup={_inputGroup}
        _inputWrapper={_inputWrapper}
        _inputSpinner={_inputSpinner}
        _inputLeftElement={_inputLeftElement}
        ref={inputRef}
        value={value}
        searchTerm={searchTerm}
        isLoading={isLoading}
        isDisabled={isDisabled}
        checkboxToggle={checkboxToggle}
        onToggleMenu={handleToggleMenu}
        onInputChange={handleInputChange}
        onInputKeyDown={handleInputKeyDown}
      />
      <TaggedAutosuggestMenu
        _menu={_menu}
        focusedItem={focusedItem}
        searchResult={searchResult}
        onListKeyDown={handleListKeyDown}
        onSelectOption={handleSelectOption}
        isNotCheckedOption={isNotCheckedOption}
        isShowingSearchResults={isShowingSearchResults}
      />
      <TaggedAutosuggestTags
        _tag={_tag}
        _tagLabel={_tagLabel}
        _tagCloseButton={_tagCloseButton}
        tags={tags}
        onRemoveTag={handleRemoveTag}
      />
      {isDisabled && (
        <Flex direction="column" align="center" mt={5} p={1} gap={4}>
          <Flex align="center" gap={2}>
            <Icon as={GiWorld} w="20px" h="20px" color="blue.500" />
            <Heading fontSize="xl">Global</Heading>
          </Flex>
          <Flex direction="column" align="center" gap={1}>
            <Heading fontSize="md" textAlign="center">
              Você realiza seu trabalho em qualquer região?
            </Heading>
            <Text fontSize="md" textAlign="center">
              Nesse modo seu perfil ficará visível em todas as regiões.
              Selecione essa opção somente se puder atender clientes em qualquer
              região.
            </Text>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default TaggedAutosuggest;
