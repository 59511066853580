export const BASE_PROVIDER_ROUTE = '/providers/resume';
export const BASE_CUSTOMER_ROUTE = '/customers/search';

export const CUSTOMER_SEARCH = '/customers/search';
export const CUSTOMER_CHAT = '/customers/chat';
export const CUSTOMER_PROFILE = '/customers/profile';
export const CUSTOMER_FEEDBACK = '/customers/feedback';
export const CUSTOMER_SEARCH_FILTERS = '/customers/search/filters';
export const CUSTOMER_SEARCH_FILTERS_LOCATION =
  '/customers/search/filters/location';
export const CUSTOMER_SEARCH_FILTERS_CATEGORY =
  '/customers/search/filters/category';
export const CUSTOMER_SEARCH_FILTERS_SERVICE =
  '/customers/search/filters/service';
export const CUSTOMER_SEARCH_NODATA = '/customers/search/nodata';

export const PROVIDER_CHAT = '/providers/chat';
export const PROVIDER_FEEDBACK = '/providers/feedback';
export const PROVIDER_RESUME = '/providers/resume';
export const PROVIDER_GALLERY = '/providers/gallery';
export const PROVIDER_PROFILE = '/providers/profile';
export const PROVIDER_REGISTER = '/providers/register';
export const PROVIDER_QUESTIONS = '/providers/questions';
