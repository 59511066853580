import React from 'react';
import ReactDOM from 'react-dom/client';

import App from 'app/App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

if (navigator && navigator.serviceWorker) {
  navigator.serviceWorker.onmessage = event => {
    if (!event.data.action) {
      return;
    }

    switch (event.data.action) {
      case 'redirect-from-notificationclick':
        window.location.href = event.data.url;
        break;
      default:
        return;
    }
  };
}

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

serviceWorkerRegistration.register({
  onUpdate: registration => {
    const waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener('statechange', (event: any) => {
        if (event?.target?.state === 'activated') {
          window.location.reload();
        }
      });
      waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
    }
  }
});
