import { Flex, Heading, Text } from '@chakra-ui/react';

const FooterCopy: React.FC = () => {
  return (
    <Flex bg="gray.900" direction="column" p={4}>
      <Flex gap={3} alignSelf="center">
        <Heading
          color="white"
          fontSize={['md', 'md', 'lg']}
          fontWeight="medium"
          borderBottomWidth={2}
          borderColor="blue.500">
          ©Workmind
        </Heading>
        <Text fontSize={['md', 'md', 'lg']} color="whiteAlpha.700">
          Todos os direitos reservados
        </Text>
      </Flex>
    </Flex>
  );
};

export default FooterCopy;
