import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDiscTest } from 'hooks';
import { Profiles, profiles } from 'constants/discTest';
import { motion, AnimatePresence } from 'framer-motion';
import {
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Heading,
  SimpleGrid,
  Spinner,
  Text,
  useMediaQuery
} from '@chakra-ui/react';

const MotionButton = motion(Button);

const DiscTest: React.FC<DiscTestProps> = ({ backRoute }) => {
  const {
    step,
    reset,
    update,
    onSelectOption,
    isLoading,
    resume: { predominant, percents }
  } = useDiscTest({
    backRoute
  });
  const navigate = useNavigate();

  const handleCancel = () => {
    if (predominant) {
      return reset();
    }

    return navigate(backRoute);
  };

  const showResult = !!predominant;
  const showResume = !!predominant && !isLoading;
  const showOptions = !predominant && !isLoading && step;

  return (
    <Flex
      flex={1}
      direction="column"
      justify="space-between"
      rowGap={8}
      p={[0, 4]}
      pt={4}
    >
      {isLoading && (
        <Flex
          h="250px"
          direction="column"
          align="center"
          justify="center"
          rowGap={4}
        >
          <Spinner />
          <Heading as="h5" fontSize="md">
            Salvando Alterações...
          </Heading>
        </Flex>
      )}
      {showResult && (
        <Box textAlign="center">
          <Heading fontSize={['xl', '2xl']} mb={3}>
            {profiles[predominant as keyof Profiles].name}
          </Heading>
          <Text>
            Gostou do resultado? Sinta-se a vontade para refazer o teste quando
            quiser.
          </Text>
        </Box>
      )}
      {showOptions && (
        <Flex direction="column" rowGap={10}>
          <Box textAlign="center">
            <Heading fontSize={['xl', '2xl']} mb={3}>
              Selecione adjetivos
            </Heading>
            <Text>
              Mesmo que você se identifique com mais de um, escolha o que mais
              se encaixa com seu perfil.
            </Text>
          </Box>
          <SimpleGrid columns={[1, 2]} gap={2}>
            <AnimatePresence exitBeforeEnter>
              {step.map((step, i) => (
                <MotionButton
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: i / 9, duration: 0.1, ease: 'linear' }}
                  key={step.id}
                  onClick={() => onSelectOption(step)}
                >
                  {step.description}
                </MotionButton>
              ))}
            </AnimatePresence>
          </SimpleGrid>
        </Flex>
      )}
      {showResume && (
        <SimpleGrid alignSelf="center" columns={[2, 4]} gap={4}>
          {(Object.keys(profiles) as (keyof Profiles)[]).map((profile, i) => (
            <ProfileResume
              key={i}
              name={profiles[profile].name}
              percent={percents[profile]}
            />
          ))}
        </SimpleGrid>
      )}
      {!isLoading && (
        <Flex
          gap={2}
          mt={[0, 14]}
          onClick={handleCancel}
          alignSelf={['stretch', 'flex-end']}
          direction={['column', 'row']}
        >
          <Button
            flex={['auto', 1]}
            type="button"
            onClick={handleCancel}
            px={7}
          >
            {predominant ? 'Refazer teste' : 'Cancelar'}
          </Button>
          <Button
            flex={['auto', 1]}
            type="button"
            colorScheme="blue"
            disabled={!predominant}
            onClick={update}
          >
            Salvar
          </Button>
        </Flex>
      )}
    </Flex>
  );
};

const ProfileResume: React.FC<ProfileResumeProps> = ({ percent, name }) => {
  const [value, setValue] = useState(0);
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    setValue(percent);
  }, [setValue, percent]);

  return (
    <Flex direction="column" align="center" rowGap={2}>
      <CircularProgress
        size={isMobile ? '90px' : '100px'}
        value={value}
        color="blue.500"
        trackColor="gray.100"
        display="flex"
        thickness="8px"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgressLabel
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Heading fontSize="sm">{name}</Heading>
          {/* <Image w="36px" h="36px" src={icon} /> */}
        </CircularProgressLabel>
      </CircularProgress>
    </Flex>
  );
};

interface DiscTestProps {
  backRoute: string;
}

interface ProfileResumeProps {
  percent: number;
  name: string;
  icon?: string;
}

export default DiscTest;
