import api from 'api';
import { useState } from 'react';
import { useAuthProvider } from 'hooks';
import { useMutation } from 'react-query';
import { parseFirebaseError } from 'utils/parseFirebaseError';
import { parseGoogleUser } from 'utils/parseGoogleUser';
import { useToast } from '@chakra-ui/react';
import { pickBy, identity } from 'lodash';

const useCustomerLogin = () => {
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const { signInWithGoogle, setUserType } = useAuthProvider();
  const { mutateAsync: checkCustomer } = useMutation(api.customers.check);
  const { mutateAsync: createCustomer } = useMutation(api.customers.create);

  const handleCustomerLogin = async () => {
    try {
      setIsLoading(true);
      const { result } = await signInWithGoogle();
      const { hasAccount } = await checkCustomer();

      if (hasAccount) {
        setUserType('CUSTOMER');
      } else {
        const resultUser = parseGoogleUser(result.user);

        await createCustomer(
          pickBy(
            {
              firstName: resultUser.firstName,
              lastName: resultUser.lastName ?? null,
              avatar: result.user.photoURL
            },
            identity
          )
        );
        toast({
          status: 'info',
          title: 'Sua conta foi criada!',
          description: 'Seja bem-vindo(a) a Workmind',
          position: 'top-right',
          duration: 4000
        });

        await result.user.getIdToken(true);
        setUserType('CUSTOMER');
      }
    } catch (err) {
      toast({
        status: 'error',
        description: parseFirebaseError((err as Error).message),
        position: 'top-right',
        duration: 1000
      });
      setIsLoading(false);
    }
  };

  return {
    customerLoginLoading: isLoading,
    customerLogin: handleCustomerLogin
  };
};

export default useCustomerLogin;
