import { TaggedAutosuggestTagsProps } from './TaggedAutosuggest.types';
import {
  Flex,
  Heading,
  CloseButton,
  VStack,
  StackDivider
} from '@chakra-ui/react';

const TaggedAutosuggestTags: React.FC<TaggedAutosuggestTagsProps> = ({
  _tag,
  _tagLabel,
  _tagCloseButton,
  tags,
  onRemoveTag
}) => {
  if (tags.length > 0) {
    return (
      <VStack
        bg="white"
        spacing={0}
        direction="column"
        align="stretch"
        divider={<StackDivider />}>
        {tags.map((tag, i) => (
          <Flex align="center" justify="space-between" key={i} p={3}>
            <Heading fontWeight="medium" fontSize="md">
              {tag.label}
            </Heading>
            <CloseButton size="md" onClick={() => onRemoveTag(i)} />
          </Flex>
        ))}
      </VStack>
    );
  }

  return null;
};

export default TaggedAutosuggestTags;
