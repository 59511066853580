import http from 'api/http';
import { FieldValues } from 'react-hook-form';

const feedback = {
  create: async (fieldValues: FieldValues) => {
    const { data, status } = await http.post('/feedbacks', fieldValues);
    if (status !== 201) throw new Error(data.message);
    return data;
  }
};

export default feedback;
