import { useState } from 'react';

const useConfirmationModal = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [payload, setPayload] = useState<any | null>(null);

  const onOpen = (payload?: any) => {
    setIsOpen(true);
    setPayload(payload!);
  };

  const onClose = () => {
    setIsOpen(false);
    setPayload(null);
  };

  const onToggle = () => setIsOpen(prev => !prev);

  return {
    isOpen,
    payload,
    onOpen,
    onClose,
    onToggle
  };
};

export default useConfirmationModal;
