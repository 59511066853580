import { createContext } from 'react';
import { Flex } from '@chakra-ui/react';
import { Dispatch, ReactNode, SetStateAction, useState } from 'react';

export const PageContext = createContext({} as ContextProps);

const Page: React.FC<PageProps> = ({ children }) => {
  const [headerHeight, setHeaderHeight] = useState<number | null>(null);

  return (
    <PageContext.Provider value={{ headerHeight, setHeaderHeight }}>
      <Flex direction="column" minH="100vh" overflow="hidden">
        {children}
      </Flex>
    </PageContext.Provider>
  );
};

interface PageProps {
  children: ReactNode;
}

interface ContextProps {
  headerHeight: number | null;
  setHeaderHeight: Dispatch<SetStateAction<number | null>>;
}

export default Page;
