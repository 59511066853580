import { forwardRef } from 'react';
import { AutosuggestInputProps } from './Autosuggest.types';
import {
  Input,
  InputGroup,
  Checkbox,
  InputRightElement,
  Flex
} from '@chakra-ui/react';

const AutosuggestInput = forwardRef<HTMLInputElement, AutosuggestInputProps>(
  (
    {
      searchTerm,
      isDisabled,
      checkboxToggle,
      onToggleMenu,
      onInputChange,
      onInputKeyDown,
      onInputBlur,
      _input,
      _inputGroup,
      _inputWrapper
    },
    ref
  ) => (
    <Flex direction="column" {..._inputWrapper}>
      <InputGroup
        autoFocus={false}
        size="lg"
        overflow="hidden"
        onClick={onToggleMenu}
        bg="white"
        {..._inputGroup}
      >
        <Input
          ref={ref}
          value={searchTerm}
          disabled={isDisabled}
          fontSize="md"
          autoFocus={false}
          onChange={onInputChange}
          onKeyDown={onInputKeyDown}
          onBlur={onInputBlur}
          {..._input}
        />
        {checkboxToggle && (
          <InputRightElement w="auto" pr={3}>
            <Checkbox
              size="sm"
              isChecked={isDisabled}
              onChange={() => checkboxToggle.onChange(!isDisabled)}
            >
              {checkboxToggle.label}
            </Checkbox>
          </InputRightElement>
        )}
      </InputGroup>
    </Flex>
  )
);

export default AutosuggestInput;
