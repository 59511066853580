import {
  ChangeEvent,
  KeyboardEvent,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { debounce } from 'lodash';
import { Option } from 'types';
import { useDisclosure, useOutsideClick, useToast } from '@chakra-ui/react';
import {
  UseTaggedAutosuggestParams,
  UseTaggedAutosuggestReturn
} from './TaggedAutosuggest.types';

export const useTaggedAutosuggest = ({
  options,
  defaultTags = [],
  isDisabled = false,
  minTags = { value: 1 },
  maxTags,
  onChangeTags,
  onSelectOption
}: UseTaggedAutosuggestParams): UseTaggedAutosuggestReturn => {
  const [value, setValue] = useState<string>('');
  const [tags, setTags] = useState<Option[]>(defaultTags);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [focusedItem, setFocusedItem] = useState<number | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [searchResult, setSearchResult] = useState<Option[]>([]);

  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const menu = useDisclosure();
  const toast = useToast();

  useOutsideClick({
    ref: containerRef,
    handler: () => menu.onClose()
  });

  const isShowingSearchResults = menu.isOpen && searchResult.length > 0;

  const cleanState = () => {
    setTags([]);
    setValue('');
    setSearchTerm('');
    setSearchResult([]);
    setFocusedItem(null);
  };

  const handleSelectOption = (option: Option) => {
    if (maxTags ? tags.length < maxTags.value : true) {
      onSelectOption && onSelectOption(option);
      setTags(prev => [...prev, option]);
      setSearchTerm('');
      setValue('');
      menu.onClose();
      inputRef.current?.focus();
      setFocusedItem(null);
    } else if (maxTags?.message) {
      toast({
        title: 'Atenção',
        description: maxTags.message,
        position: 'top-right',
        status: 'warning',
        duration: 2000,
        isClosable: true
      });
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    menu.onOpen();
  };

  const handleInputKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' && value.length > 0) {
      setValue('');
      setSearchTerm('');
    }
    if (e.key === 'ArrowDown' && isShowingSearchResults) {
      inputRef.current?.blur();
      setFocusedItem(0);
    }
  };

  const handleListKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'ArrowDown' && isShowingSearchResults) {
      setFocusedItem(prev => (prev! < 4 ? prev! + 1 : prev));
    }
    if (e.key === 'ArrowUp' && isShowingSearchResults) {
      setFocusedItem(prev => {
        if (prev! > 0) return prev! - 1;
        inputRef.current?.focus();
        return null;
      });
    }
  };

  const handleRemoveTag = (index: number) => {
    if (tags.length > minTags.value) {
      setTags(prev => prev.filter((_, i) => i !== index));
    } else if (minTags.message) {
      toast({
        title: 'Atenção',
        description: minTags.message,
        position: 'top-right',
        status: 'warning',
        duration: 2000,
        isClosable: true
      });
    }
  };

  const handleToggleMenu = () => {
    if (searchResult.length > 0 && !menu.isOpen) menu.onOpen();
  };

  const isNotCheckedOption = (option: Option) => {
    return !tags
      .map(tag => tag.value.toString())
      .includes(option.value.toString());
  };

  const debouncedSearch = useMemo(
    () =>
      debounce(searchTerm => {
        setSearchResult([]);
        const result = options
          .filter(option =>
            option.label.toLowerCase().includes(searchTerm.toLowerCase().trim())
          )
          .slice(-5);
        setSearchResult(result);
        setIsLoading(false);
      }, 600),
    [options]
  );

  useEffect(() => {
    if (searchTerm.length > 2) {
      setIsLoading(true);
      debouncedSearch(searchTerm);
    } else {
      setSearchResult([]);
    }
  }, [searchTerm, debouncedSearch]);

  useEffect(() => {
    if (onChangeTags) {
      onChangeTags(tags);
    }
  }, [tags, onChangeTags]);

  useEffect(() => {
    if (isDisabled) cleanState();
  }, [isDisabled]);

  return {
    value,
    searchTerm,
    searchResult,
    tags,
    inputRef,
    containerRef,
    isLoading,
    isDisabled,
    isShowingSearchResults,
    focusedItem,
    cleanState,
    isNotCheckedOption,
    handleToggleMenu,
    handleInputChange,
    handleInputKeyDown,
    handleSelectOption,
    handleRemoveTag,
    handleListKeyDown
  };
};

export default useTaggedAutosuggest;
