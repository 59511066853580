import { ReactNode } from 'react';
import { isSmallerThan } from 'theme/foundations/breakpoints';
import { Flex, FlexProps, useMediaQuery } from '@chakra-ui/react';

const Selectable: React.FC<SelectableProps> = ({
  icon,
  label,
  isSelected,
  onSelect,
  ...props
}) => {
  const [isMobile] = useMediaQuery(isSmallerThan('md'));

  return (
    <Flex
      onClick={onSelect}
      bg={isSelected ? 'blue.50' : 'white'}
      color={isSelected ? 'blue.700' : 'gray.400'}
      borderColor={isSelected ? 'blue.500' : 'gray.400'}
      fontWeight="medium"
      fontSize={isMobile ? 'sm' : 'md'}
      direction="column"
      align="center"
      cursor="pointer"
      borderWidth={2}
      rounded="lg"
      gap={1}
      py={4}
      {...props}>
      {icon}
      {label}
    </Flex>
  );
};

interface SelectableProps extends FlexProps {
  label: string;
  icon: ReactNode;
  isSelected: boolean;
  onSelect: () => void;
}

export default Selectable;
