import { initializeApp } from 'firebase/app';
import { getToken, Messaging } from 'firebase/messaging';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
const {
  initializeAppCheck,
  ReCaptchaV3Provider
} = require('firebase/app-check');

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJET_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const firebaseApp = initializeApp(firebaseConfig);

if (process.env.REACT_APP_FIREBASE_ENV === 'production') {
  initializeAppCheck(firebaseApp, {
    provider: new ReCaptchaV3Provider(process.env.REACT_APP_CHECK_TOKEN),
    isTokenAutoRefreshEnabled: true
  });
}

const auth = getAuth();
if (process.env.REACT_APP_FIREBASE_ENV === 'emulator') {
  connectAuthEmulator(auth, 'http://127.0.0.1:9099', {
    disableWarnings: true
  });
}

export const askNotificationPermission = async (messaging: Messaging) => {
  try {
    const token = await getToken(messaging, {
      vapidKey: process.env.REACT_APP_FIREBASE_NOTIFICATIONS_KEY
    });
    return token;
  } catch (error) {}
};

export { firebaseConfig, firebaseApp };
