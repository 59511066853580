import { extendTheme } from '@chakra-ui/react';
import foundations from './foundations';
import components from './components';
import global from './global';

const config = {
  initialColorMode: 'light',
  useSystemColorMode: false
};

const theme = extendTheme({
  config,
  ...foundations,
  components,
  styles: {
    global
  }
});

export default theme;
