import { ReactComponent as IconLogo } from 'assets/svg/logo.svg';
import {
  Flex,
  Icon,
  Heading,
  HeadingProps,
  IconProps,
  FlexProps,
  useMediaQuery
} from '@chakra-ui/react';
import { isSmallerThan } from 'theme/foundations/breakpoints';

const Logo: React.FC<LogoProps> = ({
  onClick,
  containerProps,
  iconProps,
  headingProps
}) => {
  const [isMobile] = useMediaQuery(isSmallerThan('md'));

  return (
    <Flex
      role="button"
      onClick={onClick}
      align="center"
      columnGap={2}
      {...containerProps}>
      <Icon as={IconLogo} w="24px" h="24px" {...iconProps} />
      <Heading
        fontSize={isMobile ? 'lg' : 'xl'}
        fontWeight="bold"
        color="white"
        {...headingProps}>
        Workmind
      </Heading>
    </Flex>
  );
};

interface LogoProps {
  onClick?: () => void;
  containerProps?: FlexProps;
  headingProps?: HeadingProps;
  iconProps?: IconProps;
}

export default Logo;
