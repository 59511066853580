import { useEffect, useState } from 'react';
import * as analytics from 'analytics/events/landing';

const usePWA = () => {
  const [promptInstall, setPromptInstall] = useState<any | null>(null);

  useEffect(() => {
    const handler = (e: any) => {
      e.preventDefault();
      setPromptInstall(e);
    };
    window.addEventListener('beforeinstallprompt', handler);
    return () => window.removeEventListener('transitionend', handler);
  }, [setPromptInstall]);

  const handleInstall = () => {
    if (promptInstall) {
      try {
        promptInstall.prompt();
        setPromptInstall(null);
        analytics.downloadPWA();
      } catch (err) {
        return null;
      }
    }
  };

  return { supportsPWA: !!promptInstall, onInstall: handleInstall };
};

export default usePWA;
