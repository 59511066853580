import 'leaflet/dist/leaflet.css';

import theme from 'theme';
import Router from './Router';
import ReactGA from 'react-ga4';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from 'contexts/AuthContext';
import { ChakraProvider, CSSReset } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { NotificationProvider } from 'contexts/NotificationContext';
import { NetworkStatusFeedback, AdminPanel } from 'components/common';

ReactGA.initialize(process.env.REACT_APP_FIREBASE_MEASUREMENT_ID!);

const queryClient = new QueryClient();

const App: React.FC = () => {
  return (
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <NetworkStatusFeedback />
          <AuthProvider>
            <NotificationProvider>
              <Router />
              <AdminPanel />
            </NotificationProvider>
          </AuthProvider>
        </BrowserRouter>
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      </QueryClientProvider>
      <CSSReset />
    </ChakraProvider>
  );
};

export default App;
