import {
  ReactNode,
  forwardRef,
  useRef,
  useEffect,
  useContext,
  useImperativeHandle
} from 'react';
import { Flex } from '@chakra-ui/react';
import { PageContext } from './Page';

const PageFixedHeader = forwardRef<PageFixedHeaderRef, PageFixedHeaderProps>(
  ({ children }, ref) => {
    const { setHeaderHeight } = useContext(PageContext);
    const headerRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(ref, () => ({
      getHeaderHeight: () => headerRef.current?.getBoundingClientRect()?.height
    }));

    useEffect(() => {
      if (!headerRef.current) return;
      const resizeObserver = new ResizeObserver(() => {
        if (headerRef.current) {
          const height = headerRef.current?.getBoundingClientRect()?.height;
          setHeaderHeight(height);
        }
      });
      resizeObserver.observe(headerRef.current);
      return () => resizeObserver.disconnect();
    }, [setHeaderHeight]);

    return (
      <Flex
        w="100%"
        top="0px"
        ref={headerRef}
        position="fixed"
        direction="column"
        zIndex={9}
      >
        {children}
      </Flex>
    );
  }
);

export interface PageFixedHeaderRef {
  getHeaderHeight: () => void;
}

interface PageFixedHeaderProps {
  children: ReactNode;
}

export default PageFixedHeader;
