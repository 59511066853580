import typography from './typography';
import shadows from './shadows';
import colors from './colors';
import radii from './radii';

const foundations = {
  ...typography,
  shadows,
  colors,
  radii
};

export default foundations;
