import { AuthContext } from 'contexts/AuthContext';
import { ReactNode, useContext } from 'react';
import { useLocation, Navigate } from 'react-router-dom';

const CUSTOMER_FREED_ROUTES = [
  '/customers/profile',
  '/customers/enable',
  '/customers/enable/location',
  '/customers/enable/personal',
  '/customers/profile/disc',
  '/customers/profile/personal',
  '/customers/profile/location'
];

const PROVIDER_FREED_ROUTES = [
  '/providers/dashboard',
  '/providers/dashboard/preview',
  '/providers/dashboard/personal',
  '/providers/dashboard/service',
  '/providers/dashboard/locations',
  '/providers/enable',
  '/providers/enable/personal',
  '/providers/enable/disc',
  '/providers/enable/service',
  '/providers/enable/locations'
];

const DisabledContextBlocker: React.FC<DisabledContextBlockerProps> = ({
  children
}) => {
  const { pathname } = useLocation();
  const { userData } = useContext(AuthContext);
  const { customerContextDisabled, providerContextDisabled } = userData!;

  if (customerContextDisabled && !CUSTOMER_FREED_ROUTES.includes(pathname)) {
    return <Navigate to={CUSTOMER_FREED_ROUTES[0]} replace />;
  }

  if (providerContextDisabled && !PROVIDER_FREED_ROUTES.includes(pathname)) {
    return <Navigate to={PROVIDER_FREED_ROUTES[0]} replace />;
  }

  return <>{children}</>;
};

interface DisabledContextBlockerProps {
  children: ReactNode;
}

export default DisabledContextBlocker;
