const globalStyles = {
  '::-webkit-scrollbar-track': {
    borderRadius: '6px',
    backgroundColor: 'white'
  },
  '::-webkit-scrollbar': {
    width: '10px'
  },
  '::-webkit-scrollbar-thumb': {
    borderRadius: '6px',
    backgroundColor: 'blackAlpha.200'
  },
  '@media(max-width: 768px)': {
    '*::-webkit-scrollbar': {
      display: 'none'
    }
  }
};

export default globalStyles;
