import useAutosuggest from './useAutosuggest';
import AutosuggestInput from './AutosuggestInput';
import AutosuggestMenu from './AutosuggestMenu';
import { AutosuggestProps } from './Autosuggest.types';
import { Flex } from '@chakra-ui/react';

const Autosuggest: React.FC<AutosuggestProps> = ({
  options,
  selected,
  isDisabled = false,
  onReset,
  onChange,
  checkboxToggle,
  _container,
  _menu,
  _input,
  _inputWrapper,
  _inputGroup,
  _inputSpinner
}) => {
  const {
    searchTerm,
    searchResults,
    inputRef,
    containerRef,
    focusedItem,
    isNotCheckedOption,
    isShowingSearchResults,
    handleToggleMenu,
    handleInputChange,
    handleInputBlur,
    handleInputKeyDown,
    handleSelectOption,
    handleListKeyDown
  } = useAutosuggest({
    options,
    isDisabled,
    onChange,
    selected,
    onReset
  });

  return (
    <Flex
      ref={containerRef}
      direction="column"
      position="relative"
      {..._container}
    >
      <AutosuggestInput
        _input={_input}
        _inputGroup={_inputGroup}
        _inputWrapper={_inputWrapper}
        _inputSpinner={_inputSpinner}
        ref={inputRef}
        value={selected?.value!}
        searchTerm={searchTerm}
        isDisabled={isDisabled}
        checkboxToggle={checkboxToggle}
        onToggleMenu={handleToggleMenu}
        onInputChange={handleInputChange}
        onInputKeyDown={handleInputKeyDown}
        onInputBlur={handleInputBlur}
      />
      <AutosuggestMenu
        _menu={_menu}
        focusedItem={focusedItem}
        searchResults={searchResults}
        onListKeyDown={handleListKeyDown}
        onSelectOption={handleSelectOption}
        isNotCheckedOption={isNotCheckedOption}
        isShowingSearchResults={isShowingSearchResults}
      />
    </Flex>
  );
};

export default Autosuggest;
