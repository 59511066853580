import {
  useEffect,
  useRef,
  useState,
  ChangeEvent,
  KeyboardEvent,
  useCallback
} from 'react';
import { useDisclosure, useOutsideClick } from '@chakra-ui/react';
import { Option } from 'types';
import {
  UseAutosuggestParams,
  UseAutosuggestReturn
} from './Autosuggest.types';

const useAutosuggest = ({
  options,
  selected,
  isDisabled,
  onReset,
  onChange
}: UseAutosuggestParams): UseAutosuggestReturn => {
  const [focusedItem, setFocusedItem] = useState<number | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>(selected?.label ?? '');
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const menu = useDisclosure();

  useOutsideClick({
    ref: containerRef,
    handler: () => menu.onClose()
  });

  const searchResults =
    !!searchTerm && searchTerm.length > 2
      ? options
          .filter(option =>
            option.label.toLowerCase().includes(searchTerm.toLowerCase().trim())
          )
          .slice(-5)
      : [];

  const isShowingSearchResults =
    menu.isOpen && !!searchTerm && searchResults.length > 0;

  const isNotCheckedOption = (option: Option) => {
    return selected?.value.toString() !== option.value.toString();
  };

  const cleanState = useCallback(() => {
    onReset && onReset();
    setSearchTerm('');
    setFocusedItem(null);
  }, [onReset]);

  const handleSelectOption = (option: Option) => {
    onChange(option);
    setSearchTerm(option.label);
    menu.onClose();
    inputRef.current?.focus();
    setFocusedItem(null);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    menu.onOpen();
  };

  const handleInputKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'ArrowDown' && isShowingSearchResults) {
      inputRef.current?.blur();
      setFocusedItem(0);
    }
  };

  const handleListKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'ArrowDown' && isShowingSearchResults) {
      setFocusedItem(prev => (prev! < 4 ? prev! + 1 : prev));
    }
    if (e.key === 'ArrowUp' && isShowingSearchResults) {
      setFocusedItem(prev => {
        if (prev! > 0) return prev! - 1;
        inputRef.current?.focus();
        return null;
      });
    }
  };

  const handleInputBlur = () => {
    if (!searchTerm) {
      setSearchTerm(selected?.label!);
    }
  };

  const handleToggleMenu = () => {
    if (searchResults.length > 0 && !menu.isOpen) menu.onOpen();
  };

  useEffect(() => {
    if (isDisabled) cleanState();
  }, [isDisabled, cleanState]);

  return {
    selected,
    searchTerm,
    searchResults,
    inputRef,
    containerRef,
    focusedItem,
    isDisabled,
    isNotCheckedOption,
    isShowingSearchResults,
    handleToggleMenu,
    handleInputChange,
    handleInputBlur,
    handleInputKeyDown,
    handleSelectOption,
    handleListKeyDown
  };
};

export default useAutosuggest;
