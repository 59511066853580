import providers from './controllers/providers.controller';
import customers from './controllers/customers.controller';
import categories from './controllers/categories.controller';
import feedbacks from './controllers/feedbacks.controller';
import chats from './controllers/chats.controller';
import admin from './controllers/admin.controller';

const api = {
  providers,
  customers,
  categories,
  feedbacks,
  chats,
  admin
};

export default api;
