import { ComponentType, lazy } from 'react';

export const lazyRetry = (component: () => Promise<any>, path: string) => {
  const promise: Promise<{ default: ComponentType<any> }> = new Promise(
    (resolve, reject) => {
      const hasRefreshed = JSON.parse(
        window.sessionStorage.getItem(`retry-${path}-refreshed`) || 'false'
      );
      component()
        .then(component => {
          window.sessionStorage.setItem(`retry-${path}-refreshed`, 'false');
          resolve(component);
        })
        .catch(err => {
          if (!hasRefreshed) {
            window.sessionStorage.setItem(`retry-${path}-refreshed`, 'true');
            return window.location.reload();
          }
          reject(err);
        });
    }
  );
  return promise;
};

export const lazyWithPreload = <T extends ComponentType<any>>(
  factory: () => Promise<{ default: T }>
): PreloadableComponent<T> => {
  const Component = lazy(factory) as any;
  Component.preload = factory;
  return Component;
};

export type PreloadableComponent<T extends ComponentType<any>> = T & {
  preload: () => Promise<T>;
};
