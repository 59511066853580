import React, { ComponentPropsWithoutRef, ReactNode } from 'react';
import { FieldValues, FormProvider, UseFormReturn } from 'react-hook-form';
import { Flex, FlexProps } from '@chakra-ui/react';

const Form = <T extends FieldValues>({
  children,
  methods,
  ...props
}: FlexProps & FormProps<T>) => {
  return (
    <FormProvider {...methods}>
      <Flex as="form" display="flex" direction="column" rowGap={5} {...props}>
        {children}
      </Flex>
    </FormProvider>
  );
};

interface FormProps<T> extends ComponentPropsWithoutRef<'form'> {
  methods: UseFormReturn<T, any>;
  children: ReactNode;
}

export default Form;
