import { useEffect, useRef, memo } from 'react';
import { AutosuggestMenuItemProps } from './Autosuggest.types';
import { Button } from '@chakra-ui/react';

const AutosuggestMenuItem: React.FC<AutosuggestMenuItemProps> = ({
  index,
  option,
  _button,
  onSelectOption,
  focusedItem
}) => {
  const menuItemRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (focusedItem === index) {
      menuItemRef.current?.focus();
    }
  }, [focusedItem, index]);

  return (
    <Button
      ref={menuItemRef}
      pl={4}
      h="37px"
      w="100%"
      fontSize="md"
      fontWeight="regular"
      color="gray.800"
      rounded="none"
      variant="ghost"
      alignItems="center"
      justifyContent="space-between"
      _focus={{ bg: 'blue.50' }}
      onClick={() => onSelectOption(option)}
      {..._button}
    >
      {option.label}
    </Button>
  );
};

export default memo(AutosuggestMenuItem);
