import AutosuggestMenuItem from './AutosuggestMenuItem';
import { AutosuggestMenuProps } from './Autosuggest.types';
import { VStack } from '@chakra-ui/react';

const AutosuggestMenu: React.FC<AutosuggestMenuProps> = ({
  _menu,
  focusedItem,
  searchResults,
  isNotCheckedOption,
  isShowingSearchResults,
  onListKeyDown,
  onSelectOption
}) => {
  if (isShowingSearchResults) {
    return (
      <VStack
        as="ul"
        py={1}
        w="100%"
        top="100%"
        bg="white"
        spacing={0}
        rounded="xl"
        zIndex="modal"
        overflow="hidden"
        position="absolute"
        direction="column"
        listStyleType="none"
        borderWidth={1}
        onKeyDown={onListKeyDown}
        {..._menu}
      >
        {searchResults.map((option, index) =>
          isNotCheckedOption(option) ? (
            <AutosuggestMenuItem
              index={index}
              option={option}
              key={option.value}
              focusedItem={focusedItem}
              onSelectOption={onSelectOption}
            />
          ) : null
        )}
      </VStack>
    );
  }

  return null;
};

export default AutosuggestMenu;
