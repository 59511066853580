import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { LoadingScreen } from 'components/common';
import { AuthContext } from 'contexts/AuthContext';
import DisabledContextBlocker from './DisabledContextBlocker';
import DisabledAccountBlocker from './DisabledAccountBlocker';
import AccountTypeBlocker from './AccountTypeBlocker';

const PrivateElement = (Component: JSX.Element) => {
  const { userData, loading } = useContext(AuthContext);

  if (loading) {
    return <LoadingScreen />;
  }

  if (!loading && !userData) {
    return <Navigate to="/" replace />;
  }

  if (!loading && userData) {
    return (
      <DisabledContextBlocker>
        <DisabledAccountBlocker>
          <AccountTypeBlocker>{Component}</AccountTypeBlocker>
        </DisabledAccountBlocker>
      </DisabledContextBlocker>
    );
  }

  return null;
};

export default PrivateElement;
