import { forwardRef } from 'react';
import { FiCircle } from 'react-icons/fi';
import { TaggedAutosuggestInputProps } from './TaggedAutosuggest.types';
import {
  Input,
  Icon,
  InputGroup,
  InputLeftElement,
  Spinner,
  Checkbox,
  InputRightElement,
  Flex
} from '@chakra-ui/react';

const TaggedAutosuggestInput = forwardRef<
  HTMLInputElement,
  TaggedAutosuggestInputProps
>(
  (
    {
      searchTerm,
      isLoading,
      isDisabled,
      checkboxToggle,
      onToggleMenu,
      onInputChange,
      onInputKeyDown,
      _input,
      _inputIcon,
      _inputGroup,
      _inputWrapper,
      _inputSpinner,
      _inputLeftElement
    },
    ref
  ) => (
    <Flex direction="column" py={5} {..._inputWrapper}>
      <InputGroup onClick={onToggleMenu} bg="white" {..._inputGroup}>
        <InputLeftElement {..._inputLeftElement}>
          {!isLoading && (
            <Icon
              w="16px"
              h="16px"
              as={FiCircle}
              strokeWidth={3}
              color={isDisabled ? 'gray.300' : 'gray.900'}
              {..._inputIcon}
            />
          )}
          {isLoading && <Spinner w="16px" h="16px" {..._inputSpinner} />}
        </InputLeftElement>
        <Input
          ref={ref}
          value={searchTerm}
          disabled={isDisabled}
          fontSize="md"
          onChange={onInputChange}
          onKeyDown={onInputKeyDown}
          {..._input}
        />
        {checkboxToggle && (
          <InputRightElement w="auto" pr={3}>
            <Checkbox
              size="sm"
              isChecked={isDisabled}
              onChange={() => checkboxToggle.onChange(!isDisabled)}>
              {checkboxToggle.label}
            </Checkbox>
          </InputRightElement>
        )}
      </InputGroup>
    </Flex>
  )
);

export default TaggedAutosuggestInput;
