import { PageContext } from './Page';
import { ReactNode, useContext } from 'react';
import { Flex, FlexProps, useMediaQuery } from '@chakra-ui/react';
import breakpoints, { isSmallerThan } from 'theme/foundations/breakpoints';
import { useSwipeable } from 'react-swipeable';
import { motion } from 'framer-motion';

const MotionFlex = motion(Flex);

const PageBody: React.FC<PageBodyProps> = ({
  children,
  _wrapper,
  _container,
  onSwipedLeft = () => null,
  onSwipedRight = () => null
}) => {
  const [isMobile] = useMediaQuery(isSmallerThan('xl'));
  const { headerHeight } = useContext(PageContext);
  const marginTop = headerHeight ? `${headerHeight}px` : 0;

  const handlers = useSwipeable({
    onSwipedLeft,
    onSwipedRight
  });

  return (
    <MotionFlex
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      flex={1}
      zIndex={1}
      mt={marginTop}
      direction="column"
      {..._wrapper}>
      <Flex
        flex={1}
        w="100%"
        maxW={breakpoints.xl}
        overflow="hidden"
        direction="column"
        alignSelf="center"
        position="relative"
        px={isMobile ? 4 : 0}
        py={4}
        {..._container}
        {...handlers}>
        {children}
      </Flex>
    </MotionFlex>
  );
};

interface PageBodyProps {
  children: ReactNode;
  _wrapper?: FlexProps;
  _container?: FlexProps;
  onSwipedLeft?: () => void;
  onSwipedRight?: () => void;
}

export default PageBody;
