import { StyleConfig } from '@chakra-ui/theme-tools';

const FormError: StyleConfig = {
  baseStyle: {
    text: {
      fontSize: 'xs',
      fontWeight: 'medium',
      color: 'red.500'
    }
  }
};

export default FormError;
