import { StyleConfig } from '@chakra-ui/theme-tools';

const varHome = () => {
  return {
    tab: {
      fontWeight: 'medium',
      fontSize: 'md',
      color: ['whiteAlpha.700', 'gray.500'],
      _selected: {
        color: ['white', 'gray.900'],
        borderBottomWidth: [3, 3],
        borderColor: ['white', 'gray.700'],
        shadow: 'none'
      },
      _active: {
        shadow: 'none'
      },
      _focus: {
        shadow: 'none'
      }
    },
    tablist: {
      bg: ['blue.500', 'white'],
      border: 'none',
      pt: [0, 4],
      px: [5, 0]
    }
  };
};

const Theme: StyleConfig = {
  variants: {
    home: varHome
  }
};

export default Theme;
