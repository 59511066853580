import { Flex, FlexProps } from '@chakra-ui/react';

const PageHeader: React.FC<FlexProps> = ({ children, ...props }) => {
  return (
    <Flex direction="column" {...props}>
      {children}
    </Flex>
  );
};

export default PageHeader;
