import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCustomerLogin } from 'hooks';
import { AuthContext } from 'contexts/AuthContext';
import { Customer, Provider } from 'types';
import { IconType } from 'react-icons/lib';
import { Button, Flex, Heading } from '@chakra-ui/react';
import { PROVIDER_REGISTER } from 'app/Router/Router.constants';

const MenuToggleAccountContext: React.FC = () => {
  const navigate = useNavigate();

  const { customerLogin } = useCustomerLogin();
  const { userType, userData, changeToCustomer, changeToProvider } =
    useContext(AuthContext);

  const customerData = userType === 'CUSTOMER' ? (userData as Customer) : null;
  const providerData = userType === 'PROVIDER' ? (userData as Provider) : null;
  const isProvider = !!customerData?.isProvider;
  const isCustomer = !!providerData?.isCustomer;

  const handler = () => {
    return userType === 'PROVIDER' ? changeToCustomer() : changeToProvider();
  };

  const registerRedirect = () => {
    return userType === 'PROVIDER'
      ? customerLogin()
      : navigate(PROVIDER_REGISTER);
  };

  const handleToggle = () => {
    return isProvider || isCustomer ? handler() : registerRedirect();
  };

  const assignments = {
    text: {
      CUSTOMER: isProvider
        ? 'Prestador de Serviço'
        : 'Criar conta de prestador de serviço',
      PROVIDER: isCustomer ? 'Cliente' : 'Criar conta de cliente',
      ADMIN: ''
    }
  };

  return (
    <Flex direction="column" rowGap={1}>
      <Heading px={3} fontSize="xs" fontWeight="medium">
        Alternar conta
      </Heading>
      <Button
        px={3}
        variant="ghost"
        fontWeight="normal"
        justifyContent="flex-start"
        rounded="none"
        onClick={handleToggle}>
        {assignments.text[userType!]}
      </Button>
    </Flex>
  );
};

export interface MenuToggleAccountContextProps {
  text: string;
  icon: IconType;
  onToggle: () => void;
}

export default MenuToggleAccountContext;
