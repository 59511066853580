import React, { FormEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import masks, { AvailableMasks } from './InputMask.utils';
import {
  FormControl,
  FormLabel,
  Input,
  InputProps,
  FormErrorMessage,
  FormHelperText
} from '@chakra-ui/react';

const InputMask: React.FC<InputMaskProps> = ({
  mask,
  label,
  name,
  isRequired = false,
  helperText,
  ...props
}) => {
  const {
    register,
    trigger,
    formState: { errors }
  } = useFormContext();

  const errorMessage = errors[name]?.message;
  const dataTestIdErrorMessage = `error-message-${name}`;

  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    masks[mask](e);
    register(name).onChange(e);
    if (!!errorMessage) trigger(name);
  };

  return (
    <FormControl isRequired={isRequired} isInvalid={!!errorMessage}>
      {label && <FormLabel htmlFor={`input-${name}`}>{label}</FormLabel>}
      <Input
        id={`input-${name}`}
        {...register(name)}
        onChange={handleChange}
        {...props}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      {errorMessage && (
        <FormErrorMessage data-testid={dataTestIdErrorMessage}>
          {errorMessage}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};

interface InputMaskProps extends InputProps {
  name: string;
  mask: AvailableMasks;
  label?: string;
  error?: string;
  helperText?: string;
}

export default InputMask;
