const parseFirebaseError = (message: string) => {
  var regExp = /\(([^)]+)\)/;
  var matches = regExp.exec(message);

  if (!matches) {
    return 'Ocorreu um problema, tente novamente mais tarde.';
  } else {
    switch (matches[1]) {
      case 'auth/wrong-password':
        return 'Usuário ou senha incorreto.';
      case 'auth/user-not-found':
        return 'Usuário ou senha incorreto.';
      case 'auth/popup-closed-by-user':
        return 'Você cancelou o popup de autenticação.';
      case 'auth/email-already-in-use':
        return 'Este email já está cadastrado.';
      default:
        return 'Ocorreu um problema, tente novamente mais tarde.';
    }
  }
};

export { parseFirebaseError };
