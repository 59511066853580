import { ReactNode, useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AuthContext } from 'contexts/AuthContext';

const DisabledAccountBlocker: React.FC<DisabledAccountBlockerProps> = ({
  children
}) => {
  const { userData, userType } = useContext(AuthContext);
  const { pathname } = useLocation();

  const enabledAccount = userData?.enabled;
  const enabledRoutes = pathname.includes('/enable');

  if (!enabledAccount && !enabledRoutes) {
    return (
      <Navigate
        to={`/${userType === 'PROVIDER' ? 'providers' : 'customers'}/enable`}
        replace
      />
    );
  }

  return <>{children}</>;
};

interface DisabledAccountBlockerProps {
  children: ReactNode;
}

export default DisabledAccountBlocker;
