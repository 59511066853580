import { User } from 'firebase/auth';

interface ParsedGoogleUser {
  firstName: string;
  lastName: string;
  email: string;
  phone: string | null;
}

const parseGoogleUser = (user: User): ParsedGoogleUser => {
  const names = (user.displayName || '').split(' ');
  const firstName = names[0];
  names.shift();
  const lastName = names.join(' ');
  const phone = user.phoneNumber || user.providerData[0].phoneNumber;

  return {
    firstName,
    lastName,
    email: user.email!,
    phone
  };
};

export type { ParsedGoogleUser };
export { parseGoogleUser };
