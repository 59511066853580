import { Flex, Heading, Text } from '@chakra-ui/react';

const ListNoData: React.FC<ListNoDataProps> = ({ title, text }) => {
  return (
    <Flex direction="column" align="center" gap={2} pt={10} px={6}>
      <Heading fontSize="md">{title}</Heading>
      <Text textAlign="center" fontSize="md">
        {text}
      </Text>
    </Flex>
  );
};

interface ListNoDataProps {
  title: string;
  text: string;
}

export default ListNoData;
