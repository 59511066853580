import { useRef } from 'react';
import { motion } from 'framer-motion';
import { Flex, FlexProps } from '@chakra-ui/react';
import { getRandomInt } from 'utils/functions';

const MotionFlex = motion(Flex);

const images = [
  'https://storage.googleapis.com/workmind-7928a.appspot.com/banners/builders.jpg',
  'https://storage.googleapis.com/workmind-7928a.appspot.com/banners/electrician.jpg',
  'https://storage.googleapis.com/workmind-7928a.appspot.com/banners/mecanical.jpg',
  'https://storage.googleapis.com/workmind-7928a.appspot.com/banners/personalTrainer.jpg',
  'https://storage.googleapis.com/workmind-7928a.appspot.com/banners/psychologist.jpg',
  'https://storage.googleapis.com/workmind-7928a.appspot.com/banners/teacher.jpg'
];

const Banner: React.FC<FlexProps> = props => {
  const backgroundImage = useRef(images[getRandomInt(0, images.length)]);

  return (
    <MotionFlex
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      backgroundImage={`url(${backgroundImage.current})`}
      {...props}
    />
  );
};

export default Banner;
