import api from 'api';
import { useAuthCustomer } from 'hooks';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { Avatar, Flex, Heading, useToast } from '@chakra-ui/react';
import {
  createContext,
  PropsWithChildren,
  useEffect,
  useState,
  useCallback
} from 'react';
import {
  isSupported,
  getMessaging,
  onMessage,
  Unsubscribe
} from 'firebase/messaging';
import { askNotificationPermission, firebaseApp } from 'app/firebase';

const NotificationToast: React.FC<NotificationToastProps> = ({
  title,
  avatar,
  description,
  onClick
}) => {
  return (
    <Flex
      p={3}
      w="350px"
      as="button"
      bg="gray.900"
      align="center"
      columnGap={3}
      rounded="md"
      overflow="hidden"
      onClick={onClick}
    >
      <Avatar size="md" src={avatar} name={title} />
      <Flex flex={1} direction="column" align="flex-start" rowGap={1}>
        <Heading as="h5" fontSize="md" color="white" isTruncated>
          {title}
        </Heading>
        <Heading
          maxW="250px"
          as="small"
          fontSize="sm"
          fontFamily="Body"
          color="white"
          isTruncated
        >
          {description}
        </Heading>
      </Flex>
    </Flex>
  );
};

const NotificationContext = createContext({} as NotificationContextProps);
const NotificationProvider: React.FC<PropsWithChildren<unknown>> = ({
  children
}) => {
  const { mutateAsync: mutateCustomer } = useMutation(
    api.customers.addNotificationToken
  );
  const { mutateAsync: mutateProvider } = useMutation(
    api.providers.addNotificationToken
  );
  const [notificationToken, setNotificationToken] = useState<string>();
  const { userType, userData } = useAuthCustomer();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const toast = useToast();

  const sendToken = useCallback(async () => {
    try {
      if (!notificationToken || !userType || !userData) return;

      if (userType === 'CUSTOMER') {
        await mutateCustomer(notificationToken);
      } else if (userType === 'PROVIDER') {
        await mutateProvider(notificationToken);
      }
    } catch (err) {
      toast({
        status: 'error',
        description: (err as Error).message,
        position: 'top-right',
        duration: 1000
      });
    }
  }, [
    userType,
    notificationToken,
    toast,
    userData,
    mutateCustomer,
    mutateProvider
  ]);

  useEffect(() => {
    sendToken();
  }, [sendToken]);

  useEffect(() => {
    const getToken = async () => {
      const supportFCM = await isSupported();
      if (supportFCM) {
        const messaging = getMessaging(firebaseApp);
        const token = await askNotificationPermission(messaging);
        if (token) {
          setNotificationToken(token);
        }
      }
    };

    getToken();
  }, [navigate, toast]);

  useEffect(() => {
    let listener: Unsubscribe;
    if (notificationToken) {
      listener = onMessage(getMessaging(), ({ notification, data }) => {
        const userIsOnChatRoute =
          pathname.includes('/customers/chat') ||
          pathname.includes('/providers/chat');
        if (notification && !userIsOnChatRoute) {
          toast({
            duration: 4000,
            position: 'top-right',
            render: () => (
              <NotificationToast
                title={notification?.title!}
                avatar={data?.avatar!}
                description={notification?.body!}
                onClick={() => {
                  toast.closeAll();
                  navigate(data?.pathname!);
                }}
              />
            )
          });
        }
      });
    }
    return () => {
      if (listener) {
        listener();
      }
    };
  }, [notificationToken, pathname, toast, navigate]);

  return (
    <NotificationContext.Provider value={{}}>
      {children}
    </NotificationContext.Provider>
  );
};

interface NotificationContextProps {}

interface NotificationToastProps {
  title: string;
  description: string;
  avatar: string;
  onClick: () => void;
}

export { NotificationProvider, NotificationContext };
