import api from 'api';
import { useChat } from 'hooks';
import { Provider } from 'types';
import { useMediaQuery } from '@chakra-ui/react';
import useAuthProvider from '../hooks/useAuthProvider';
import * as analytics from 'analytics/events/customers';
import { isSmallerThan } from 'theme/foundations/breakpoints';
import React, { createContext, ReactNode, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export const ProviderDetailsContext = createContext(
  {} as ProviderDetailsContextProps
);

export const ProviderDetailsContextProvider: React.FC<
  ProviderDetailsContextProviderProps
> = ({ children }) => {
  const [isMobile] = useMediaQuery(isSmallerThan('lg'));
  const [isLoadingChat, setIsLoadingChat] = useState<boolean>(false);
  const { providerId } = useParams<{ providerId: string }>();
  const { userData } = useAuthProvider();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const { findOrCreateChat } = useChat();

  const { data: provider, isLoading: isLoadingProvider } = useQuery(
    ['providerDetails', providerId],
    () => api.providers.findById({ id: providerId! }),
    {
      enabled: !!providerId,
      refetchOnWindowFocus: false
    }
  );

  const { mutate, isLoading: isLoadingFavorite } = useMutation(
    api.providers.favorite,
    {
      onMutate: async () => {
        await queryClient.cancelQueries(['providerDetails', providerId]);
        const prevProviderDetails = queryClient.getQueryData([
          'providerDetails',
          providerId
        ]);
        queryClient.setQueryData<Provider | undefined>(
          ['providerDetails', providerId],
          prev => {
            if (prev) {
              const { favorite, likes } = prev;
              return {
                ...prev,
                favorite: !favorite,
                likes: favorite ? likes - 1 : likes + 1
              };
            }
            return prev;
          }
        );
        return { prevProviderDetails };
      }
    }
  );

  const handleStartChat = async () => {
    if (providerId) {
      setIsLoadingChat(true);
      const chatId = await findOrCreateChat(providerId);
      setIsLoadingChat(false);
      if (chatId) {
        analytics.chatStart();
        navigate(`/customers/chat/${chatId}`, { replace: true });
      }
    }
  };

  const handleFavorite = () => providerId && mutate({ providerId });
  const isDifferentFromHimself = userData?.id !== provider?.id;

  const showFavorite = isDifferentFromHimself;

  const showStartChat =
    !!provider && isDifferentFromHimself && !pathname.includes('/chat/');

  return (
    <ProviderDetailsContext.Provider
      value={{
        provider: provider as Provider,
        isMobile,
        isLoadingChat,
        isLoadingFavorite,
        isLoadingProvider,
        showFavorite,
        showStartChat,
        onFavorite: handleFavorite,
        onStartChat: handleStartChat
      }}>
      {children}
    </ProviderDetailsContext.Provider>
  );
};

export interface ProviderDetailsContextProviderProps {
  children: ReactNode;
}

export interface ProviderDetailsContextProps {
  provider: Provider;
  isMobile: boolean;
  isLoadingChat: boolean;
  isLoadingProvider: boolean;
  isLoadingFavorite: boolean;
  showFavorite: boolean;
  showStartChat: boolean;
  onStartChat: () => void;
  onFavorite: () => void;
}
