import List from './List';
import ListItem from './ListItem';
import ListNoData from './ListNoData';
import ListItemSkeleton from './ListItemSkeleton';
import ListLoadMoreButton from './ListLoadMoreButton';

export default Object.assign(List, {
  Item: ListItem,
  NoData: ListNoData,
  ItemSkeleton: ListItemSkeleton,
  LoadMoreButton: ListLoadMoreButton
});
