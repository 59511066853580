const colors = {
  gray: {
    50: '#F8F9FA',
    100: '#F1F3F5',
    200: '#E9ECEF',
    300: '#DEE2E6',
    400: '#CED4DA',
    500: '#ADB5BD',
    600: '#868E96',
    700: '#495057',
    800: '#343A40',
    900: '#0D131B'
  },
  blue: {
    50: '#eaf1ff',
    100: '#b6d0ff',
    200: '#9dbfff',
    300: '#84afff',
    400: '#6a9eff',
    500: '#377dff',
    600: '#045cff',
    700: '#0052ea',
    800: '#0049d0',
    900: '#00379d'
  },
  orange: {
    50: '#fff3e0',
    100: '#ffe0b2',
    200: '#ffcc80',
    300: '#ffb74d',
    400: '#ffa726',
    500: '#ff9800',
    600: '#fb8c00',
    700: '#f57c00',
    800: '#ef6c00',
    900: '#e65100'
  },
  purple: {
    50: '#f3e8ff',
    100: '#d2bcf8',
    200: '#b091ef',
    300: '#8c64e7',
    400: '#7638df',
    500: '#6820c6',
    600: '#59189b',
    700: '#46116f',
    800: '#2e0944',
    900: '#15011c'
  },
  green: {
    '50': '#EBF9FA',
    '100': '#C7EFF0',
    '200': '#A2E4E6',
    '300': '#7EDADD',
    '400': '#5AD0D3',
    '500': '#35C5CA',
    '600': '#2B9EA1',
    '700': '#207679',
    '800': '#154F51',
    '900': '#0B2728'
  }
};

export default colors;
