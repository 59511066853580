import { Suspense } from 'react';
import { Private } from 'components/common';
import { useConcurrentTransition } from 'hooks';
import { Routes, Route } from 'react-router-dom';
import { lazyRetry, lazyWithPreload } from './Router.utils';

const Landing = lazyWithPreload(() =>
  lazyRetry(
    () => import(/* webpackPrefetch: true */ 'pages/common/Landing'),
    'Landing'
  )
);

const Feedback = lazyWithPreload(() =>
  lazyRetry(() => import('pages/common/Feedback'), 'Feedback')
);

const NotFound = lazyWithPreload(() =>
  lazyRetry(() => import('pages/common/NotFound'), 'NotFound')
);

const CustomerChat = lazyWithPreload(() =>
  lazyRetry(() => import('pages/customers/CustomerChat'), 'CustomerChat')
);

const CustomerChatMessages = lazyWithPreload(() =>
  lazyRetry(
    () => import('pages/customers/CustomerChat/CustomerChatMessages'),
    'CustomerChatMessages'
  )
);

const CustomerEnableAccount = lazyWithPreload(() =>
  lazyRetry(
    () => import('pages/customers/CustomerEnableAccount'),
    'CustomerEnableAccount'
  )
);

const CustomerModalLocation = lazyWithPreload(() =>
  lazyRetry(
    () => import('pages/customers/CustomerModalLocation'),
    'CustomerModalLocation'
  )
);

const CustomerModalProviderGallery = lazyWithPreload(() =>
  lazyRetry(
    () => import('pages/customers/CustomerModalProviderGallery'),
    'CustomerModalProviderGallery'
  )
);

const CustomerSearch = lazyWithPreload(() =>
  lazyRetry(() => import('pages/customers/CustomerSearch'), 'CustomerSearch')
);

const CustomerSearchNoData = lazyWithPreload(() =>
  lazyRetry(
    () => import('pages/customers/CustomerSearch/CustomerSearchNoData'),
    'CustomerSearchContent'
  )
);

const CustomerSearchMobileFilters = lazyWithPreload(() =>
  lazyRetry(
    () => import('pages/customers/CustomerSearch/CustomerSearchMobileFilters'),
    'CustomerSearchMobileFilters'
  )
);

const CustomerSearchModalLocation = lazyWithPreload(() =>
  lazyRetry(
    () => import('pages/customers/CustomerSearch/CustomerSearchModalLocation'),
    'CustomerSearchModalLocation'
  )
);

const CustomerSearchModalCategory = lazyWithPreload(() =>
  lazyRetry(
    () => import('pages/customers/CustomerSearch/CustomerSearchModalCategory'),
    'CustomerSearchModalCategory'
  )
);

const CustomerSearchModalService = lazyWithPreload(() =>
  lazyRetry(
    () => import('pages/customers/CustomerSearch/CustomerSearchModalService'),
    'CustomerSearchModalService'
  )
);

const CustomerProfile = lazyWithPreload(() =>
  lazyRetry(() => import('pages/customers/CustomerProfile'), 'CustomerProfile')
);

const CustomerModalPersonalData = lazyWithPreload(() =>
  lazyRetry(
    () => import('pages/customers/CustomerModalPersonalData'),
    'CustomerModalPersonalData'
  )
);

const ProviderChat = lazyWithPreload(() =>
  lazyRetry(() => import('pages/providers/ProviderChat'), 'ProviderChat')
);

const ProviderChatMessages = lazyWithPreload(() =>
  lazyRetry(
    () => import('pages/providers/ProviderChat/ProviderChatMessages'),
    'ProviderChatMessages'
  )
);

const ProviderRegister = lazyWithPreload(() =>
  lazyRetry(
    () => import('pages/providers/ProviderRegister'),
    'ProviderRegister'
  )
);

const ProviderEnableAccount = lazyWithPreload(() =>
  lazyRetry(
    () => import('pages/providers/ProviderEnableAccount'),
    'ProviderEnableAccount'
  )
);

const ProviderModalPersonalData = lazyWithPreload(() =>
  lazyRetry(
    () => import('pages/providers/ProviderModalPersonalData'),
    'ProviderModalPersonalData'
  )
);

const ProviderModalSocialMedias = lazyWithPreload(() =>
  lazyRetry(
    () => import('pages/providers/ProviderModalSocialMedias'),
    'ProviderModalSocialMedias'
  )
);

const ProviderModalService = lazyWithPreload(() =>
  lazyRetry(
    () => import('pages/providers/ProviderModalService'),
    'ProviderModalService'
  )
);

const ProviderModalLocations = lazyWithPreload(() =>
  lazyRetry(
    () => import('pages/providers/ProviderModalLocations'),
    'ProviderModalLocations'
  )
);

const ProviderModalUpload = lazyWithPreload(() =>
  lazyRetry(
    () => import('pages/providers/ProviderModalUpload'),
    'ProviderModalUpload'
  )
);

const ProviderModalPhoto = lazyWithPreload(() =>
  lazyRetry(
    () => import('pages/providers/ProviderModalPhoto'),
    'ProviderModalPhoto'
  )
);

const ProviderResume = lazyWithPreload(() =>
  lazyRetry(() => import('pages/providers/ProviderResume'), 'ProviderResume')
);

const ProviderGallery = lazyWithPreload(() =>
  lazyRetry(() => import('pages/providers/ProviderGallery'), 'ProviderGallery')
);

const ProviderProfile = lazyWithPreload(() =>
  lazyRetry(() => import('pages/providers/ProviderProfile'), 'ProviderProfile')
);

const ProviderQuestions = lazyWithPreload(() =>
  lazyRetry(
    () => import('pages/providers/ProviderQuestions'),
    'ProviderQuestions'
  )
);

const ProviderModalQuestion = lazyWithPreload(() =>
  lazyRetry(
    () => import('pages/providers/ProviderModalQuestion'),
    'ProviderModalQuestion'
  )
);

const ProviderDetails = lazyWithPreload(() =>
  lazyRetry(
    () => import('components/provider/ProviderDetails'),
    'ProviderDetails'
  )
);

const ModalUpdateAvatar = lazyWithPreload(() =>
  lazyRetry(
    () => import('components/common/ModalUpdateAvatar'),
    'ModalUpdateAvatar'
  )
);

export const preloadCustomerPages = () => {
  CustomerChat.preload();
  CustomerSearch.preload();
  CustomerProfile.preload();
  CustomerSearchNoData.preload();
  CustomerSearchModalLocation.preload();
  CustomerSearchModalCategory.preload();
  CustomerSearchModalService.preload();
  CustomerSearchMobileFilters.preload();
  CustomerModalLocation.preload();
  CustomerModalPersonalData.preload();
  CustomerModalProviderGallery.preload();
  ProviderDetails.preload();
  ModalUpdateAvatar.preload();
};

export const preloadProviderPages = () => {
  ProviderResume.preload();
  ProviderChat.preload();
  ProviderGallery.preload();
  ProviderProfile.preload();
  ProviderQuestions.preload();
  ProviderModalQuestion.preload();
  ProviderModalPersonalData.preload();
  ProviderModalService.preload();
  ProviderModalLocations.preload();
  ModalUpdateAvatar.preload();
};

const Router = () => {
  const location = useConcurrentTransition();
  return (
    <Suspense fallback={<></>}>
      <Routes location={location}>
        <Route path="/" element={<Landing />} />
        <Route path="/customers">
          <Route path="chat" element={Private(<CustomerChat />)}>
            <Route path=":id" element={Private(<CustomerChatMessages />)} />
            <Route
              path="provider/:providerId"
              element={Private(<ProviderDetails />)}>
              <Route
                path="gallery/:photoIndex"
                element={Private(<CustomerModalProviderGallery />)}
              />
            </Route>
          </Route>
          <Route path="feedback" element={Private(<Feedback />)} />
          <Route path="enable" element={Private(<CustomerEnableAccount />)}>
            <Route
              path="location"
              element={Private(<CustomerModalLocation />)}
            />
            <Route
              path="personal"
              element={Private(<CustomerModalPersonalData />)}
            />
          </Route>
          <Route path="search" element={Private(<CustomerSearch />)}>
            <Route path=":providerId" element={Private(<ProviderDetails />)}>
              <Route
                path="gallery/:photoIndex"
                element={Private(<CustomerModalProviderGallery />)}
              />
            </Route>
            <Route path="nodata" element={Private(<CustomerSearchNoData />)} />
            <Route
              path="filters"
              element={Private(<CustomerSearchMobileFilters />)}>
              <Route
                path="location"
                element={Private(<CustomerSearchModalLocation />)}
              />
              <Route
                path="category"
                element={Private(<CustomerSearchModalCategory />)}
              />
              <Route
                path="service"
                element={Private(<CustomerSearchModalService />)}
              />
            </Route>
          </Route>
          <Route path="profile" element={Private(<CustomerProfile />)}>
            <Route
              path="personal"
              element={Private(<CustomerModalPersonalData />)}
            />
            <Route
              path="location"
              element={Private(<CustomerModalLocation />)}
            />
            <Route path="avatar" element={Private(<ModalUpdateAvatar />)} />
          </Route>
        </Route>
        <Route path="/providers">
          <Route path="feedback" element={Private(<Feedback />)} />
          <Route path="chat" element={Private(<ProviderChat />)}>
            <Route path=":id" element={Private(<ProviderChatMessages />)} />
          </Route>
          <Route path="register" element={<ProviderRegister />} />
          <Route path="enable" element={Private(<ProviderEnableAccount />)}>
            <Route
              path="personal"
              element={Private(<ProviderModalPersonalData />)}
            />
            <Route
              path="social-medias"
              element={Private(<ProviderModalSocialMedias />)}
            />
            <Route path="service" element={Private(<ProviderModalService />)} />
            <Route
              path="locations"
              element={Private(<ProviderModalLocations />)}
            />
            <Route path="avatar" element={Private(<ModalUpdateAvatar />)} />
          </Route>
          <Route path="resume" element={Private(<ProviderResume />)} />
          <Route path="gallery" element={Private(<ProviderGallery />)}>
            <Route path="upload" element={Private(<ProviderModalUpload />)} />
            <Route path="photos" element={Private(<ProviderModalPhoto />)} />
          </Route>
          <Route path="questions" element={Private(<ProviderQuestions />)}>
            <Route
              path="details"
              element={Private(<ProviderModalQuestion />)}
            />
          </Route>
          <Route path="profile" element={Private(<ProviderProfile />)}>
            <Route
              path="personal"
              element={Private(<ProviderModalPersonalData />)}
            />
            <Route path="service" element={Private(<ProviderModalService />)} />
            <Route
              path="locations"
              element={Private(<ProviderModalLocations />)}
            />
            <Route path="avatar" element={Private(<ModalUpdateAvatar />)} />
          </Route>
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};

export default Router;
