import axios, { AxiosRequestConfig } from 'axios';

import { getAuth } from 'firebase/auth';

const http = axios.create({
  baseURL:
    process.env.REACT_APP_FIREBASE_ENV === 'emulator'
      ? process.env.REACT_APP_DEVELOPMENT_API
      : process.env.REACT_APP_PRODUCTION_API,
  validateStatus: status => status < 500
});

http.interceptors.request.use(async (config: AxiosRequestConfig) => {
  const { currentUser } = getAuth();
  if (currentUser) {
    const idToken = await currentUser.getIdToken();
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${idToken}`
      }
    };
  }
  return config;
});

export default http;
