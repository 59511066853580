import { Flex, Skeleton, SkeletonCircle } from '@chakra-ui/react';

const ListItemSkeleton: React.FC = () => {
  return (
    <Flex align="center" gap={5} px={4} py={3}>
      <SkeletonCircle w="55px" h="55px" borderRadius="xl" rounded="xl" />
      <Flex flex={1} direction="column" gap={2}>
        <Skeleton rounded="full" h="16px" />
        <Skeleton rounded="full" w="80%" h="16px" />
      </Flex>
    </Flex>
  );
};

export default ListItemSkeleton;
