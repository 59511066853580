import * as React from 'react';
import { Flex, useTheme } from '@chakra-ui/react';
import Spinner from 'react-spinners/BounceLoader';

const LoadingScreen: React.FC = () => {
  const { colors } = useTheme();

  return (
    <Flex
      h="100vh"
      direction="column"
      align="center"
      justify="center"
      bg="blue.500"
    >
      <Spinner size={200} color={colors?.blue[800]} />
    </Flex>
  );
};

export default LoadingScreen;
