const breakpoints = {
  xs: 350,
  sm: 425,
  md: 768,
  lg: 999,
  xl: 1200,
  '2xl': 1920
};

export type Breakpoints = keyof typeof breakpoints;

export const isSmallerThan = (value: Breakpoints | number) => {
  return `(max-width: ${
    typeof value === 'string' ? breakpoints[value] : value + 1
  }px)`;
};

export const isBiggerThan = (value: Breakpoints | number) => {
  return `(min-width: ${
    typeof value === 'string' ? breakpoints[value] : value + 1
  }px)`;
};

export default breakpoints;
