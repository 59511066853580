import { StyleConfig, SystemStyleFunction } from '@chakra-ui/theme-tools';

export const varOutline: SystemStyleFunction = () => {
  return {
    field: {
      rounded: 'lg',
      fontSize: 'md',
      fontWeight: 'medium',
      borderColor: 'gray.400',
      color: 'gray.900',
      _placeholder: {
        color: 'gray.600',
        fontWeight: 'regular'
      }
    }
  };
};

const Input: StyleConfig = {
  variants: {
    outline: varOutline
  }
};

export default Input;
