import api from 'api';
import { useMutation } from 'react-query';
import { useToast } from '@chakra-ui/react';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import {
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithPopup,
  signOut,
  User,
  UserCredential,
  signInWithCustomToken
} from 'firebase/auth';
import { ParsedGoogleUser, parseGoogleUser } from 'utils/parseGoogleUser';
import { Customer, Provider, UserType } from 'types';
import { useLocation, useNavigate } from 'react-router-dom';
import { ChatProvider } from './ChatContext';
import { LoadingScreen } from 'components/common';
import { usePWA } from 'hooks';
import AuthIndicator from 'components/common/AuthIndicator';
import { CUSTOMER_SEARCH } from 'app/Router/Router.constants';
import {
  preloadCustomerPages,
  preloadProviderPages
} from '../app/Router/Router';

const USER_TYPE_KEY = '@workmind/user_type';

const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);

const AuthProvider: React.FC<AuthProviderProps> = ({ children, value }) => {
  const [userData, setUserData] = useState<ContextUserType>(null);
  const [userType, setUserType] = useState<UserType | null>(null);
  const [switchUserType, setSwitchUserType] = useState<UserType | null>(null);
  const [tempUser, setTempUser] = useState<ParsedGoogleUser | null>(null);
  const [loading, setLoading] = useState(true);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [checkLogin, setCheckLogin] = useState(false);
  const { supportsPWA, onInstall } = usePWA();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const toast = useToast();

  const userIsOnCustomerPages = useMemo(
    () => pathname.includes('/customers/'),
    [pathname]
  );
  const userIsOnProviderPages = useMemo(
    () => pathname.includes('/providers/'),
    [pathname]
  );

  const { mutateAsync: getProvider } = useMutation(api.providers.get);
  const { mutateAsync: getCustomer } = useMutation(api.customers.get);
  const { mutateAsync: mutateLogin } = useMutation(api.admin.login);

  const provider = new GoogleAuthProvider();
  const auth = getAuth();

  const signInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const userToken = await result.user.getIdToken();
      return { token: userToken, result };
    } catch (err) {
      throw err;
    }
  };

  const logout = async (callback?: () => void) => {
    try {
      await signOut(auth);
      setUserData(null);
      setUserType(null);
      setSwitchUserType(null);
      if (callback) {
        callback();
      }
    } catch (err) {
      toast({
        status: 'error',
        description: 'Erro ao sair. Por favor, tente novamente',
        position: 'top-right',
        duration: 1000
      });
    }
  };

  const loginAsProvider = useCallback(
    async (props: {
      id: string;
      email: string;
      isCustomer: boolean;
      isAdmin: boolean;
      isCustom: boolean;
    }) => {
      const { data } = await getProvider();
      preloadProviderPages();
      setUserData({
        ...data,
        ...props
      });
      setUserType('PROVIDER');
      setSwitchUserType(null);
      setCheckLogin(true);
    },
    [setUserData, setUserType, getProvider, setSwitchUserType]
  );

  const loginAsCustomer = useCallback(
    async (props: {
      id: string;
      email: string;
      isProvider: boolean;
      isAdmin: boolean;
      isCustom: boolean;
    }) => {
      const { data } = await getCustomer();
      preloadCustomerPages();
      setUserData({
        ...data,
        ...props
      });
      setUserType('CUSTOMER');
      setSwitchUserType(null);
      setCheckLogin(true);
    },
    [setUserData, setUserType, getCustomer, setSwitchUserType]
  );

  const loginAsAnotherUser = useCallback(async (userId: string) => {
    try {
      const token = await mutateLogin(userId);
      await signInWithCustomToken(auth, token);
      setCheckLogin(true);
    } catch (err) {
      toast({
        status: 'error',
        description: (err as Error).message,
        position: 'top-right',
        duration: 3000
      });
    }
  }, []);

  const refreshUser = useCallback(
    async (user: User | null) => {
      try {
        const authUser = user ?? auth.currentUser;
        if (!userType && !switchUserType) {
          return;
        }

        if (authUser) {
          const userAuth = await authUser.getIdTokenResult();
          const authUserType = (userAuth.claims.type ?? []) as UserType[];
          const isProvider = authUserType.includes('PROVIDER');
          const isCustomer = authUserType.includes('CUSTOMER');
          const isAdmin = authUserType.includes('ADMIN');
          const isCustom = userAuth.signInProvider === 'custom';
          const type = switchUserType ?? userType;

          if (!isProvider && !isCustomer) {
            setLoading(false);
            return;
          }
          if (type === 'PROVIDER') {
            if (isProvider) {
              await loginAsProvider({
                id: authUser.uid,
                email: authUser.email!,
                isCustomer,
                isAdmin,
                isCustom
              });
            } else if (isCustomer) {
              await loginAsCustomer({
                id: authUser.uid,
                email: authUser.email!,
                isProvider,
                isAdmin,
                isCustom
              });
            }
          } else if (type === 'CUSTOMER') {
            if (isCustomer) {
              await loginAsCustomer({
                id: authUser.uid,
                email: authUser.email!,
                isProvider,
                isAdmin,
                isCustom
              });
            } else if (isProvider) {
              await loginAsProvider({
                id: authUser.uid,
                email: authUser.email!,
                isCustomer,
                isAdmin,
                isCustom
              });
            }
          }
        } else {
          setUserType(null);
          setSwitchUserType(null);
          setUserData(null);
          setLoading(false);
        }
      } catch (err) {
        toast({
          status: 'error',
          description: 'Comportamento inesperado. Por favor, tente novamente',
          position: 'top-right',
          duration: 1000
        });
        setUserData(null);
        setLoading(false);
      }
    },
    [
      auth.currentUser,
      toast,
      userType,
      switchUserType,
      setUserData,
      setUserType,
      setSwitchUserType,
      setLoading,
      loginAsCustomer,
      loginAsProvider
    ]
  );

  const changeToCustomer = useCallback(() => {
    setSwitchUserType('CUSTOMER');
    setUserData(null);
    setLoading(true);
    setCheckLogin(true);
  }, [setSwitchUserType, setLoading, setCheckLogin, setUserData]);

  const changeToProvider = useCallback(() => {
    setSwitchUserType('PROVIDER');
    setUserData(null);
    setLoading(true);
    setCheckLogin(true);
  }, [setSwitchUserType, setCheckLogin, setLoading, setUserData]);

  useEffect(() => {
    const subscription = onAuthStateChanged(auth, async user => {
      if (user) {
        setTempUser(parseGoogleUser(user));
      }
      refreshUser(user);
    });
    return subscription;
  }, [auth, refreshUser]);

  const finishLogin = useCallback(() => {
    if (userType === 'CUSTOMER') {
      if (!pathname.includes(CUSTOMER_SEARCH)) {
        navigate('/customers/search');
      }
    } else if (userType === 'PROVIDER') {
      navigate('/providers/resume');
    }
    setCheckLogin(false);
  }, [userType, setCheckLogin, navigate]);

  useEffect(() => {
    if (userData && userType) {
      setLoading(false);

      if (checkLogin) {
        finishLogin();
      }

      if (!isFirstRender) return;

      setIsFirstRender(false);
      if (userType === 'CUSTOMER') {
        if (!userIsOnCustomerPages) {
          navigate('/customers/search');
          setLoading(false);
        }
      } else if (userType === 'PROVIDER') {
        if (!userIsOnProviderPages) {
          navigate('/providers/resume');
          setLoading(false);
        }
      }
    }
  }, [
    userData,
    userType,
    isFirstRender,
    checkLogin,
    userIsOnCustomerPages,
    userIsOnProviderPages,
    navigate,
    finishLogin
  ]);

  useEffect(() => {
    if (userType) {
      localStorage.setItem(USER_TYPE_KEY, userType);
    }
  }, [userType]);

  useEffect(() => {
    const userType = localStorage.getItem(USER_TYPE_KEY) as UserType;
    setUserType(userType ?? 'PROVIDER');
  }, [setUserType]);

  const isLoggedIn = useMemo(() => !!userData, [userData]);
  const isLoading = useMemo(() => loading, [loading]);

  return (
    <AuthContext.Provider
      value={
        value ?? {
          userData,
          userType,
          setUserData,
          authenticated: userData !== null,
          loading,
          tempUser,
          setTempUser,
          signInWithGoogle,
          logout,
          refreshUser,
          setLoadingAuth: setLoading,
          setCheckLogin,
          setUserType: setSwitchUserType,
          changeToProvider,
          changeToCustomer,
          loginAsAnotherUser,
          supportsPWA,
          onInstall
        }
      }>
      {isLoading ? (
        <LoadingScreen />
      ) : isLoggedIn ? (
        <>
          {userData?.isCustom && <AuthIndicator />}
          <ChatProvider>{children}</ChatProvider>
        </>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
};

interface AuthContextProps {
  userData: ContextUserType;
  userType: UserType | null;
  setUserData: React.Dispatch<React.SetStateAction<ContextUserType>>;
  authenticated: boolean;
  loading: boolean;
  tempUser: ParsedGoogleUser | null;
  setTempUser: React.Dispatch<React.SetStateAction<ParsedGoogleUser | null>>;
  signInWithGoogle: () => Promise<{ token: string; result: UserCredential }>;
  logout: (callback?: () => void) => Promise<void>;
  refreshUser: (user: User | null) => Promise<void>;
  setLoadingAuth: React.Dispatch<React.SetStateAction<boolean>>;
  setCheckLogin: React.Dispatch<React.SetStateAction<boolean>>;
  setUserType: React.Dispatch<React.SetStateAction<UserType | null>>;
  changeToProvider: () => void;
  changeToCustomer: () => void;
  loginAsAnotherUser: (userId: string) => Promise<void>;
  supportsPWA: boolean;
  onInstall: () => void;
}

interface AuthProviderProps {
  children?: ReactNode;
  value?: AuthContextProps;
}

type ContextUserType = Customer | Provider | null;

export type { AuthProviderProps, AuthContextProps };
export { AuthProvider, AuthContext };
