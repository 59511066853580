import React, {
  createContext,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
  useCallback
} from 'react';
import { useAuthCustomer } from 'hooks';
import {
  getFirestore,
  query,
  collection,
  where,
  orderBy,
  limit,
  getDocs,
  QueryDocumentSnapshot,
  CollectionReference,
  onSnapshot,
  Unsubscribe,
  doc,
  startAfter,
  updateDoc,
  increment,
  runTransaction,
  DocumentReference,
  serverTimestamp,
  Timestamp
} from 'firebase/firestore';
import { Chat, ChatCreateMessage, ChatMessage } from 'types';
import { useMutation } from 'react-query';
import api from 'api';
import { useToast, useMediaQuery } from '@chakra-ui/react';
import { useNavigate, useLocation } from 'react-router-dom';
import { isSmallerThan } from 'theme/foundations/breakpoints';
import { CUSTOMER_CHAT, PROVIDER_CHAT } from 'app/Router/Router.constants';

const ChatContext = createContext<ChatContextProps>({} as ChatContextProps);

const MESSAGES_LIMIT = 20;
const CHATS_LIMIT = 10;

const ChatProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [chats, setChats] = useState<QueryDocumentSnapshot<Chat>[]>([]);
  const [selectedChat, setSelectedChat] = useState<Chat | null>(null);
  const [selectedChatId, setSeletedChatId] = useState<string | null>(null);
  const [hasMoreMessages, setHasMoreMessages] = useState(false);
  const [hasMoreChats, setHasMoreChats] = useState(false);
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [loadingNewChats, setLoadingNewChats] = useState(false);
  const [loadingStaleChats, setLoadingStaleChats] = useState(false);
  const [loadingNextMessagesPage, setLoadingNextMessagesPage] = useState(false);
  const [loadingNextChatsPage, setLoadingNextChatsPage] = useState(false);

  const [isMobile] = useMediaQuery(isSmallerThan('lg'));
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isDesktop = !isMobile;

  const { mutateAsync } = useMutation(api.chats.create);
  const { userType, userData } = useAuthCustomer();
  const db = getFirestore();
  const toast = useToast();

  const isCustomer = useMemo(() => userType === 'CUSTOMER', [userType]);

  const staleChatsQuery = useMemo(
    () =>
      query<Chat>(
        collection(db, 'chats') as CollectionReference<Chat>,
        where(isCustomer ? 'customer.id' : 'provider.id', '==', userData?.id),
        where('newChat', 'in', isCustomer ? [true, false] : [false]),
        where(
          isCustomer ? 'newMessages.toCustomer' : 'newMessages.toProvider',
          '<=',
          0
        ),
        orderBy(
          isCustomer ? 'newMessages.toCustomer' : 'newMessages.toProvider',
          'desc'
        ),
        orderBy('lastMessage.createdAt', 'desc'),
        limit(CHATS_LIMIT)
      ),
    [db, isCustomer, userData.id]
  );

  const fetchNextMessagesPage = useCallback(async () => {
    try {
      if (
        !messages.length ||
        !hasMoreMessages ||
        !selectedChat ||
        loadingNextMessagesPage
      )
        return;

      setLoadingNextMessagesPage(true);
      const lastMessage = messages[messages.length - 1];
      const messagesQuery = query<ChatMessage>(
        collection(
          db,
          `chats/${selectedChat.id}/messages`
        ) as CollectionReference<ChatMessage>,
        orderBy('createdAt', 'desc'),
        orderBy('__name__', 'desc'),
        limit(MESSAGES_LIMIT),
        startAfter(lastMessage.createdAt, lastMessage.id)
      );

      const fetchedMessages = await getDocs(messagesQuery);

      if (fetchedMessages.docs.length < MESSAGES_LIMIT)
        setHasMoreMessages(false);

      const newMessages = fetchedMessages.docs.map(doc => ({
        ...doc.data(),
        pending: false,
        id: doc.id
      }));
      setMessages(prev => [...prev, ...newMessages]);
    } catch (err) {
      //ERROR GETTING NEXT MESSAGES PAGE
    } finally {
      setLoadingNextMessagesPage(false);
    }
  }, [
    db,
    selectedChat,
    hasMoreMessages,
    loadingNextMessagesPage,
    setHasMoreMessages,
    setMessages,
    setLoadingNextMessagesPage,
    messages
  ]);

  const listenToChatMessages = useCallback(
    (chatId: string) => {
      try {
        setHasMoreMessages(true);
        setLoadingMessages(true);
        setMessages([]);
        const messagesQuery = query<ChatMessage>(
          collection(
            db,
            `chats/${chatId}/messages`
          ) as CollectionReference<ChatMessage>,
          orderBy('createdAt', 'desc'),
          limit(MESSAGES_LIMIT)
        );

        const listener = onSnapshot<ChatMessage>(
          messagesQuery,
          snapshot => {
            setLoadingMessages(false);
            setMessages(prev => {
              const newMessages = snapshot.docs.map(doc => {
                return {
                  ...doc.data(),
                  pending: false,
                  error: false,
                  id: doc.id
                };
              });
              const oldMessages = [
                ...prev.filter(
                  msg => newMessages.findIndex(m => m.id === msg.id) === -1
                )
              ];
              const pendingOldMessages: ChatMessage[] = [];
              const notPendingOldMessages: ChatMessage[] = [];
              oldMessages.forEach(msg => {
                if (msg.pending) {
                  pendingOldMessages.push(msg);
                } else {
                  notPendingOldMessages.push(msg);
                }
              });

              return [
                ...pendingOldMessages,
                ...newMessages,
                ...notPendingOldMessages
              ];
            });
          },
          err => {
            setLoadingMessages(false);
          }
        );

        return listener;
      } catch (err) {
        toast({
          description:
            'Não foi possível buscar as mensagens para esta conversa. Por favor tente novamente mais tarde!',
          position: 'top-right',
          duration: 1000
        });
        setLoadingMessages(false);
      }
    },
    [db, toast, setMessages, setLoadingMessages]
  );

  const findOrCreateChat = async (userId: string) => {
    try {
      const chatQuery = query<Chat>(
        collection(db, 'chats') as CollectionReference<Chat>,
        where(isCustomer ? 'customer.id' : 'provider.id', '==', userData?.id),
        where(isCustomer ? 'provider.id' : 'customer.id', '==', userId),
        limit(1)
      );

      const chats = await getDocs(chatQuery);

      // Providers are not able to create chats
      if (chats.docs.length === 0 && isCustomer) {
        const newChat = await mutateAsync(userId);
        setSelectedChat(newChat);
        setSeletedChatId(newChat.id);
        return newChat.id;
      } else {
        const chatDoc = chats.docs[0];
        setSelectedChat({ ...chatDoc.data(), id: chatDoc.id });
        setSeletedChatId(chatDoc.id);
        return chatDoc.id;
      }
    } catch (err) {
      toast({
        status: 'error',
        description:
          'Ocorreu um problema ao encontrar a conversa. Por favor tente novamente mais tarde!',
        position: 'top-right',
        duration: 1000
      });
    }
  };

  const fetchNextChatsPage = async () => {
    try {
      if (!chats.length || !hasMoreChats || loadingNextChatsPage) return;

      setLoadingNextChatsPage(true);
      const lastChat = chats[chats.length - 1];
      const chatsQuery = query<Chat>(staleChatsQuery, startAfter(lastChat));

      const chatDocs = await getDocs<Chat>(chatsQuery);

      if (chatDocs.docs.length < CHATS_LIMIT) {
        setHasMoreChats(false);
      }

      setChats(prev => [...prev, ...chatDocs.docs]);
    } catch (err) {
      // ERROR GETTING CHATS
    } finally {
      setLoadingNextChatsPage(false);
    }
  };

  const listenToChats = useCallback(() => {
    try {
      setHasMoreChats(true);
      setLoadingNewChats(true);
      setLoadingStaleChats(true);
      const chatsQuery = query<Chat>(
        collection(db, 'chats') as CollectionReference<Chat>,
        where(isCustomer ? 'customer.id' : 'provider.id', '==', userData?.id),
        where('newChat', 'in', isCustomer ? [true, false] : [false]),
        where(
          isCustomer ? 'newMessages.toCustomer' : 'newMessages.toProvider',
          '>',
          0
        ),
        orderBy(
          isCustomer ? 'newMessages.toCustomer' : 'newMessages.toProvider',
          'desc'
        ),
        orderBy('lastMessage.createdAt', 'desc')
      );

      const listener1 = onSnapshot<Chat>(chatsQuery, snapshot => {
        setChats(prev => {
          const filteredPrev = prev.filter(
            chatDoc =>
              snapshot.docs.findIndex(doc => doc.id === chatDoc.id) === -1
          );
          return [...snapshot.docs, ...filteredPrev];
        });
        setLoadingNewChats(false);
      });

      const listener2 = onSnapshot<Chat>(staleChatsQuery, snapshot => {
        setChats(prev => {
          const withNewMessages: QueryDocumentSnapshot<Chat>[] = [];
          const withoutNewMessages: QueryDocumentSnapshot<Chat>[] = [];
          for (const doc of prev) {
            const docData = doc.data();
            const key = isCustomer ? 'toCustomer' : 'toProvider';
            if (
              snapshot.docs.findIndex(snapDoc => snapDoc.id === doc.id) === -1
            ) {
              if (docData.newMessages[key] > 0) withNewMessages.push(doc);
              else withoutNewMessages.push(doc);
            }
          }

          return [...withNewMessages, ...snapshot.docs, ...withoutNewMessages];
        });
        setLoadingStaleChats(false);
      });

      return { listener1, listener2 };
    } catch (err) {
      // ERROR GETTING CHATS
      setLoadingNewChats(false);
      setLoadingStaleChats(false);
    }
  }, [
    db,
    isCustomer,
    userData.id,
    staleChatsQuery,
    setHasMoreChats,
    setLoadingNewChats,
    setLoadingStaleChats,
    setChats
  ]);

  const onSendMessageError = useCallback(
    (messageId: string) => {
      setMessages(prev => {
        const messageIndex = prev.findIndex(
          message => message.id === messageId
        );
        if (messageIndex !== -1) {
          const newMessages = [...prev];
          newMessages[messageIndex].error = true;
          return newMessages;
        }
        return prev;
      });
    },
    [setMessages]
  );

  const sendMessage = useCallback(
    async (message: string, messageId?: string) => {
      if (!selectedChat) return;

      const messagePath = `chats/${selectedChat.id}/messages${
        messageId ? '/' + messageId : ''
      }`;
      const messageRef = messageId
        ? doc(db, messagePath)
        : doc(collection(db, messagePath));

      try {
        const chatRef = doc(db, 'chats', selectedChat.id);
        const dateNow = Timestamp.fromDate(new Date());

        const newMessage: ChatCreateMessage = {
          message,
          receiverId: isCustomer
            ? selectedChat?.provider.id
            : selectedChat?.customer.id,
          senderId: userData?.id,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp()
        };

        setMessages(prev => {
          const messageToAdd = {
            ...newMessage,
            pending: true,
            error: false,
            id: messageRef.id,
            createdAt: dateNow,
            updatedAt: dateNow
          };
          if (!messageId) {
            return [messageToAdd, ...prev];
          }
          const messageIndex = prev.findIndex(msg => msg.id === messageId);
          if (messageIndex !== -1) {
            const newMessages = [...prev];
            newMessages[messageIndex] = messageToAdd;
            return newMessages;
          }
          return prev;
        });

        runTransaction(db, async transaction => {
          transaction.set(messageRef, newMessage);
          transaction.update(chatRef, {
            lastMessage: newMessage,
            newChat: false,
            totalMessages: increment(1),
            [isCustomer ? 'newMessages.toProvider' : 'newMessages.toCustomer']:
              increment(1)
          });
        }).catch(err => {
          onSendMessageError(messageRef.id);
        });
      } catch (err) {
        onSendMessageError(messageRef.id);
      }
    },
    [selectedChat, db, isCustomer, userData.id, onSendMessageError]
  );

  const updateNewMessageCount = async (chatId: string, value = 0) => {
    try {
      const chatRef = doc(db, `chats/${chatId}`);
      await updateDoc(chatRef, {
        [isCustomer ? 'newMessages.toCustomer' : 'newMessages.toProvider']:
          value
      });
    } catch (err) {
      toast({
        description: 'Ocorreu um erro ao atualizar as mensagens.',
        position: 'top-right',
        duration: 2000
      });
    }
  };

  useEffect(() => {
    let chatListener: Unsubscribe | undefined;
    let messagesListener: Unsubscribe | undefined;
    if (selectedChatId) {
      chatListener = onSnapshot<Chat>(
        doc(db, `chats/${selectedChatId}`) as DocumentReference<Chat>,
        snapshot => {
          const chatData = snapshot.data();
          setSelectedChat(chatData ? { ...chatData, id: snapshot.id } : null);
        }
      );
      messagesListener = listenToChatMessages(selectedChatId);
    }
    return () => {
      if (chatListener) chatListener();
      if (messagesListener) messagesListener();
    };
  }, [db, selectedChatId, listenToChatMessages]);

  useEffect(() => {
    const isChatsPath = [CUSTOMER_CHAT, PROVIDER_CHAT].includes(pathname);
    const hasChats = chats.length > 0;

    if (isDesktop && isChatsPath && hasChats) {
      const lastChatId = chats[0]?.id;

      if (lastChatId) {
        setSeletedChatId(lastChatId);
        navigate(
          `/${isCustomer ? 'customers' : 'providers'}/chat/${lastChatId}`
        );
      }
    }
  }, [pathname, isDesktop, isCustomer, chats, navigate, setSelectedChat]);

  return (
    <ChatContext.Provider
      value={{
        isMobile,
        findOrCreateChat,
        listenToChats,
        selectChat: setSeletedChatId,
        sendMessage,
        fetchNextMessagesPage,
        fetchNextChatsPage,
        updateNewMessageCount,
        chats: chats.map(chat => ({ ...chat.data(), id: chat.id })),
        messages,
        selectedChat,
        loadingMessages,
        loadingNextMessagesPage,
        loadingChats: loadingNewChats || loadingStaleChats,
        loadingNextChatsPage
      }}>
      {children}
    </ChatContext.Provider>
  );
};

interface ChatContextProps {
  isMobile: boolean;
  findOrCreateChat: (userId: string) => Promise<string | undefined>;
  listenToChats: () =>
    | {
        listener1: Unsubscribe;
        listener2: Unsubscribe;
      }
    | undefined;
  fetchNextMessagesPage: () => Promise<void>;
  fetchNextChatsPage: () => Promise<void>;
  selectChat: React.Dispatch<React.SetStateAction<string | null>>;
  sendMessage: (message: string, messageId?: string) => void;
  updateNewMessageCount: (chatId: string, value: number) => Promise<void>;
  chats: Chat[];
  messages: ChatMessage[];
  selectedChat: Chat | null;
  loadingMessages: boolean;
  loadingNextMessagesPage: boolean;
  loadingChats: boolean;
  loadingNextChatsPage: boolean;
}

export { ChatContext, ChatProvider, MESSAGES_LIMIT, CHATS_LIMIT };
