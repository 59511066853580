import AdminPanel from './AdminPanel';
import { useAuthCustomer } from 'hooks';

const Root: React.FC = () => {
  const { userData } = useAuthCustomer();
  if (userData?.isAdmin) {
    return <AdminPanel />;
  }
  return null;
};

export default Root;
