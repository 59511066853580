import http from '../http';
import { Category, Service } from 'types/entities';

const categories = {
  list: async () => {
    const { status, data } = await http.get<{ categories: Category[] }>(
      '/categories/list'
    );

    if (status !== 200) {
      throw new Error('Ocorreu um erro, tente novamente mais tarde');
    }

    return data.categories;
  },
  getServices: async ({
    queryKey,
    removeEmpty = false
  }: {
    queryKey: any[];
    removeEmpty?: boolean;
  }) => {
    const [, categoryId] = queryKey;
    if (!categoryId) {
      return [];
    }

    const { status, data } = await http.get<{ services: Service[] }>(
      `/categories/${categoryId}/services`,
      {
        params: {
          removeEmpty
        }
      }
    );

    if (status !== 200) {
      throw new Error('Ocorreu um erro, tente novamente mais tarde');
    }

    return data.services;
  }
};

export default categories;
