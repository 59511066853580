import { FormEvent } from 'react';

const phone = (e: FormEvent<HTMLInputElement>) => {
  e.currentTarget.maxLength = 15;
  let value = e.currentTarget.value;
  value = value.replace(/\D/g, '');
  value = value.replace(/^(\d{2})(\d)/g, '($1) $2');
  value = value.replace(/(\d)(\d{4})$/, '$1-$2');
  e.currentTarget.value = value;
  return e;
};

const cpf = (e: FormEvent<HTMLInputElement>) => {
  e.currentTarget.maxLength = 14;
  let value = e.currentTarget.value;
  if (!value.match(/^(\d{3}).(\d{3}).(\d{3})-(\d{2})$/)) {
    value = value.replace(/\D/g, '');
    value = value.replace(/(\d{3})(\d)/, '$1.$2');
    value = value.replace(/(\d{3})(\d)/, '$1.$2');
    value = value.replace(/(\d{3})(\d{2})$/, '$1-$2');
    e.currentTarget.value = value;
  }
  return e;
};

const birthday = (e: FormEvent<HTMLInputElement>) => {
  e.currentTarget.maxLength = 10;
  let value = e.currentTarget.value;
  if (!value.match(/^(\d{2})[/](\d{2})[/](\d{4})$/)) {
    value = value.replace(/\D/g, '');
    value = value.replace(/(\d{2})(\d)/, '$1/$2');
    value = value.replace(/(\d{2})(\d)/, '$1/$2');
    e.currentTarget.value = value;
  }
  return e;
};

const masks = {
  phone,
  cpf,
  birthday
};

export type AvailableMasks = 'phone' | 'cpf' | 'birthday';
export default masks;
