import { Flex, Text, Image, ImageProps, useMediaQuery } from '@chakra-ui/react';
import { useState } from 'react';
import { isSmallerThan } from 'theme/foundations/breakpoints';

const ImageWithCaption: React.FC<ImageWithCaptionProps> = ({
  onClick,
  caption,
  children,
  ...props
}) => {
  const [isMobile] = useMediaQuery(isSmallerThan('lg'));
  const [isHover, setIsHover] = useState(false);

  return (
    <Flex
      shadow="xs"
      rounded="md"
      cursor="pointer"
      transition="0.3s"
      overflow="hidden"
      direction="column"
      position="relative"
      _hover={{ opacity: 0.9 }}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={onClick}>
      <Image {...props} />
      {isHover && children}
      {caption && !isMobile && (
        <Flex
          p={3}
          w="100%"
          h="50px"
          left="0px"
          bottom="0px"
          align="flex-end"
          position="absolute"
          bgGradient="linear(to-b, transparent, blackAlpha.900)">
          <Text color="white" fontSize="sm" fontWeight="semibold" noOfLines={2}>
            {caption}
          </Text>
        </Flex>
      )}
    </Flex>
  );
};

interface ImageWithCaptionProps extends ImageProps {
  caption: string;
}

export default ImageWithCaption;
