import { Stack, StackDivider, StackProps } from '@chakra-ui/react';

const List: React.FC<StackProps> = ({ children, ...props }) => {
  return (
    <Stack
      spacing={0}
      direction="column"
      divider={<StackDivider borderColor="gray.100" />}
      {...props}>
      {children}
    </Stack>
  );
};

export default List;
