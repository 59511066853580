import * as React from 'react';
import { useFormContext } from 'react-hook-form';

import {
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  Textarea as ChakraTextarea,
  TextareaProps as ChakraTextareaProps
} from '@chakra-ui/react';

const Textarea: React.FC<TextareaProps> = ({
  name,
  label,
  helperText,
  isRequired = false,
  showErrorMessage = true,
  ...props
}) => {
  const {
    register,
    formState: { errors }
  } = useFormContext();

  const errorMessage = errors[name]?.message;

  return (
    <FormControl isRequired={isRequired} isInvalid={!!errorMessage}>
      {label && <FormLabel>{label}</FormLabel>}
      <ChakraTextarea {...props} {...register(name)} />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  );
};

interface TextareaProps extends ChakraTextareaProps {
  name: string;
  label?: string;
  helperText?: string;
  showErrorMessage?: boolean;
}

export default Textarea;
